html {
    font-size: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
    color: #464948;
    overflow-x: hidden;
}

.text-content h3 {
    font-size: 40px;
    padding-bottom: 15px;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-align: center;
    color: #464948;
}

.text-content h3 span {
    color: #A52A2A;
}

.text-content h4 {
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    padding-bottom: 10px;
    color: #A52A2A;
}

@media only screen and (max-width: 571px) {
    .text-content h3 {
        font-size: 30px;
    }
}

.text-content p {
    font-size: 17px;
}

.nice-select:after {
    border-bottom: 2px solid #000000 !important;
    border-right: 2px solid #000000 !important;
    height: 7px !important;
    width: 7px !important;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: #A52A2A !important;
    color: #ffffff;
}

.nice-select .list:hover .option:not(:hover) {
    color: #464948;
}

.nice-select {
    height: 50px !important;
    line-height: 50px !important;
}

.nice-select .option {
    font-weight: 500;
    font-size: 14px;
    color: #535353;
}

.country-select {
    width: 100% !important;
}

.nice-select.open .list {
    width: 100% !important;
}

.nice-select-2 .nice-select:after {
    border-bottom: 2px solid #ffffff !important;
    border-right: 2px solid #ffffff !important;
    height: 7px !important;
    width: 7px !important;
}

.nice-select-2 .form-control {
    width: 101%;
}

.select-item {
    border-radius: 0px 5px 5px 0px !important;
    height: 50px;
    background-color: #A52A2A !important;
    color: #ffffff !important;
}

.select-item-b {
    border-radius: 5px !important;
    height: 50px;
    background-color: #ffffff !important;
    color: #464948 !important;
}

.lan-select .nice-select {
    line-height: 24px !important;
    height: 28px !important;
    width: 80px !important;
}

.select-item-2 {
    background-color: #f6f8fe;
}

.select-item-2 .current {
    color: black !important;
}

.select-input {
    border-radius: 5px 0px 0px 5px !important;
}

.type-select .nice-select {
    line-height: 45px !important;
}

.type-select .form-control {
    border-radius: 5px !important;
}

a {
    display: inline-block;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

blockquote {
    margin: 0 0 1.3em;
}

.nav-pills .nav-link.active {
    background-color: #A52A2A !important;
}

p {
    margin-bottom: 15px;
    line-height: 1.7em;
}

p:last-child {
    margin-bottom: 0px;
}

@media only screen and (max-width: 1199px) {
    p {
        line-height: 1.7em;
    }
}

img {
    max-width: 100%;
    height: auto;
}

button:focus,
input:focus,
textarea:focus {
    outline: none;
}

button,
input[type=submit],
input[type=reset],
input[type=button] {
    border: none;
    cursor: pointer;
}

input,
textarea {
    padding: 12px 25px;
    width: 100%;
}

span {
    display: inline-block;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    color: inherit;
}

blockquote {
    background-color: #f6f8fe;
    padding: 40px;
    border-radius: 10px;
    font-weight: 500;
    font-style: italic;
    position: relative;
}

blockquote .quote-icon {
    position: absolute;
    top: 0;
    left: 5%;
    font-size: 120px;
    opacity: 0.1;
}

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 10;
    padding: 10px 15px;
    background-color: #A52A2A;
    color: #ffffff;
    border: 1px solid #A52A2A;
    border-radius: 5px;
}

.scroll-top {
    background-color: #A52A2A;
}

/*-------------------------------------------------
      [ ## padding ]
  */
.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-100 {
    padding-top: 100px;
}

@media only screen and (max-width: 991px) {
    .pt-100 {
        padding-top: 80px;
    }
}

.pt-120 {
    padding-top: 120px;
}

@media only screen and (max-width: 991px) {
    .pt-120 {
        padding-top: 100px;
    }
}

.pt-150 {
    padding-top: 150px;
}

@media only screen and (max-width: 991px) {
    .pt-150 {
        padding-top: 100px;
    }
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-100 {
    padding-bottom: 100px;
}

@media only screen and (max-width: 991px) {
    .pb-100 {
        padding-bottom: 80px;
    }
}

.pb-120 {
    padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
    .pb-120 {
        padding-bottom: 100px;
    }
}

.pb-150 {
    padding-bottom: 150px;
}

@media only screen and (max-width: 991px) {
    .pb-150 {
        padding-bottom: 100px;
    }
}

.ptb-10 {
    padding: 10px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-40 {
    padding: 40px 0;
}

.ptb-50 {
    padding: 50px 0;
}

.ptb-60 {
    padding: 60px 0;
}

.ptb-80 {
    padding: 80px 0;
}

.ptb-100 {
    padding: 100px 0;
}

@media only screen and (max-width: 991px) {
    .ptb-100 {
        padding: 80px 0;
    }
}

.ptb-120 {
    padding: 120px 0;
}

@media only screen and (max-width: 991px) {
    .ptb-120 {
        padding: 100px 0;
    }
}

.ptb-150 {
    padding: 150px 0;
}

@media only screen and (max-width: 991px) {
    .ptb-150 {
        padding: 100px 0;
    }
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-150 {
    margin-top: 150px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-80 {
    margin-bottom: 80px;
}

@media only screen and (max-width: 575px) {
    .mb-80 {
        margin-bottom: 40px;
    }
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mt-10-none {
    margin-top: -10px;
}

.mt-20-none {
    margin-top: -20px;
}

.mt-30-none {
    margin-top: -30px;
}

.mt-40-none {
    margin-top: -40px;
}

.mt-50-none {
    margin-top: -50px;
}

.mt-60-none {
    margin-top: -60px;
}

.mt-80-none {
    margin-top: -80px;
}

.mt-100-none {
    margin-top: -100px;
}

.mt-120-none {
    margin-top: -120px;
}

.mt-150-none {
    margin-top: -150px;
}

.mb-10-none {
    margin-bottom: -10px;
}

.mb-20-none {
    margin-bottom: -20px;
}

.mb-30-none {
    margin-bottom: -30px;
}

.mb-40-none {
    margin-bottom: -40px;
}

.mb-50-none {
    margin-bottom: -50px;
}

.mb-60-none {
    margin-bottom: -60px;
}

.mb-80-none {
    margin-bottom: -80px;
}

@media only screen and (max-width: 575px) {
    .mb-80-none {
        margin-bottom: -40px;
    }
}

.mb-100-none {
    margin-bottom: -100px;
}

.mb-120-none {
    margin-bottom: -120px;
}

.mb-150-none {
    margin-bottom: -150px;
}

/*-------------------------------------------------
      [ ## color ]
  */
.bg--primary {
    background-color: #7367f0 !important;
}

.bg--secondary {
    background-color: #A52A2A;
}

.bg--success {
    background-color: #28c76f;
}

.bg--danger {
    background-color: #ea5455;
}

.bg--warning {
    background-color: #ff9f43;
}

.bg--info {
    background-color: #1e9ff2;
}

.bg--dark {
    background-color: #10163a;
}

.bg--base {
    background-color: #A52A2A !important;
}

.text--primary {
    color: #7367f0;
}

.text--secondary {
    color: #A52A2A;
}

.text--success {
    color: #28c76f;
}

.text--danger {
    color: #ea5455;
}

.text--warning {
    color: #ff9f43;
}

.text--info {
    color: #1e9ff2;
}

.text--dark {
    color: #10163a;
}

.text--base {
    color: #A52A2A !important;
}

.border--primary {
    border: #7367f0;
}

.border--secondary {
    border: 1px solid #A52A2A;
}

.border--success {
    border: 1px solid #28c76f;
}

.border--danger {
    border: 1px solid #ea5455;
}

.border--warning {
    border: 1px solid #ff9f43;
}

.border--info {
    border: 1px solid #1e9ff2;
}

.border--dark {
    border: 1px solid #10163a;
}

.border--base {
    border: 1px solid #e5e5e5 !important;
}

.section--bg {
    background-color: #A52A2A !important;
}

.bg--gray {
    background-color: #f6f8fe;
}

.border--rounded {
    border-radius: 3px !important;
}

.border--capsule {
    border-radius: 100px;
}

.box-shadow {
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

/*-------------------------------------------------
      [ ## scrollbar ]
  */
*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset -5px -5px 8px -1px rgba(255, 255, 255, 0.7), 5px 5px 8px -1px rgba(0, 0, 0, 0.065);
    box-shadow: inset -5px -5px 8px -1px rgba(255, 255, 255, 0.7), 5px 5px 8px -1px rgba(0, 0, 0, 0.065);
    background-color: #eaf7e4;
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: #eaf7e4;
}

*::-webkit-scrollbar-button {
    background-color: #A52A2A;
}

*::-webkit-scrollbar-thumb {
    background-color: #A52A2A;
}

/*-------------------------------------------------
      [ ## scrollToTop ]
  */
.scrollToTop {
    position: fixed;
    bottom: 0;
    right: 30px;
    width: 45px;
    height: 45px;
    line-height: 47px;
    font-weight: 600;
    background-color: #A52A2A;
    border-radius: 10px;
    color: #ffffff !important;
    font-size: 20px;
    text-align: center;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 1s;
    transition: all 1s;
    -webkit-transform: translateY(-5000%);
    transform: translateY(-5000%);
}

.scrollToTop.active {
    bottom: 30px;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

/*-------------------------------------------------
      [ ## Table ]
  */
.custom-table {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
}

@media only screen and (max-width: 1199px) {
    .custom-table thead {
        display: none;
    }
}

.custom-table thead tr {
    background: #A52A2A;
}

.custom-table thead tr th {
    border: none;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding: 10px 30px;
}

.custom-table thead tr th:first-child {
    border-radius: 5px 0 0 5px;
    text-align: left;
}

.custom-table thead tr th:last-child {
    border-radius: 0 5px 5px 0;
    text-align: right;
}

.custom-table tbody tr {
    background: #ffffff;
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.custom-table tbody tr:hover {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
}

.custom-table tbody tr td {
    border: none;
    font-weight: 600;
    text-align: center;
    color: #464948;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    padding: 8px 30px;
}

.custom-table tbody tr td:first-child {
    text-align: left;
}

.custom-table tbody tr td:last-child {
    text-align: right;
}

.custom-table tbody tr td .author-info {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom-table tbody tr td .author-info .thumb {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
}

.custom-table tbody tr td .author-info .thumb img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.custom-table tbody tr td .author-info .content {
    padding-left: 15px;
    width: calc(100% - 45px);
}

.custom-table tbody tr td a {
    color: #ffffff;
    padding: 3px 8px;
    border-radius: 3px !important;
}

.custom-table tbody tr td .btn {
    border-radius: 3px;
}

.custom-table tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}

.custom-table tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 1199px) {
    .custom-table tbody tr td {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: right;
        border-bottom: 1px solid rgba(57, 57, 57, 0.2);
    }

    .custom-table tbody tr td:last-child {
        border: none;
    }

    .custom-table tbody tr td::before {
        content: attr(data-label);
        margin-right: auto;
        color: #A52A2A;
        width: 50%;
        text-align: left;
    }
}

/*-------------------------------------------------
      [ ## slider ]
  */
.swiper-notification {
    display: none;
}

.swiper-pagination {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
}

.swiper-pagination .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgba(114, 62, 235, 0.2);
    opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background-color: #A52A2A;
    width: 25px;
    border-radius: 10px;
}

.slider-next,
.slider-prev {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    background-color: #ffffff;
    border: 1px solid rgba(57, 57, 57, 0.2);
    border-radius: 50%;
    color: #464948;
    display: inline-block;
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    cursor: pointer;
    margin-top: 60px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.slider-next:hover,
.slider-prev:hover {
    background-color: #A52A2A;
    color: #ffffff;
}

.slider-next {
    margin-left: 10px;
}

/*-------------------------------------------------
      [ ## pagination ]
  */
.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid rgba(57, 57, 57, 0.2);
    padding-top: 50px;
}

.pagination .page-item .page-link {
    font-size: 18px !important;
}

.pagination .page-item {
    text-align: center;
    padding: 3px;
}

.pagination .page-item a,
.pagination .page-item span {
    width: 40px;
    height: 35px;
    border-radius: 3px;
    background: transparent;
    border: 1px solid #A52A2A;
    color: #A52A2A;
    padding: 0;
    font-weight: 600;
    line-height: 35px;
    display: block;
    margin: 0;
}

.pagination .page-item.disabled span {
    background: transparent;
    border: 1px solid rgba(57, 57, 57, 0.2);
    color: #000000;
}

.pagination .page-item .page-link {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.pagination .page-item.active .page-item a,
.pagination .page-item.active span,
.pagination .page-item:hover .page-item a,
.pagination .page-item:hover span {
    color: #ffffff;
}

.pagination .page-item.active .page-link,
.pagination .page-item:hover .page-link {
    background-color: #A52A2A;
    border-color: transparent;
    color: #ffffff;
}

/*-------------------------------------------------
      [ ## tab ]
  */
// .nav-tabs {
//     margin: 0;
//     border: 0;
//     margin-bottom: 30px;
// }

// .nav-tabs .nav-link {
//     padding: 8px 20px;
//     border: none;
//     font-weight: 600;
//     font-size: 14px;
//     background-color: #ffffff;
//     border: 1px solid rgba(57, 57, 57, 0.2);
//     border-radius: 3px;
//     font-family: "Open Sans", sans-serif;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
// }

// .nav-tabs .nav-link:hover {
//     background-color: #A52A2A;
//     border: 1px solid #A52A2A;
//     color: #ffffff;
// }

// .nav-tabs .nav-link.active {
//     background-color: #A52A2A;
//     border: 1px solid #A52A2A;
//     color: #ffffff;
// }

// .nav-tabs .nav-link:not(:last-child) {
//     margin-right: 10px;
// }

// @media only screen and (max-width: 1199px) {
//     .nav-tabs .nav-link:not(:last-child) {
//         margin-right: 5px;
//     }
// }

/*-------------------------------------------------
      [ ## card ]
  */
.custom--card {
    border: none;
    background: #ffffff;
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}

.custom--card .card-header {
    background: #A52A2A;
    padding: 0.8rem 1.6rem;
    border: none;
}

.custom--card .card-header .card-title {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
}

.custom--card .card-header .card-title i {
    margin-right: 5px;
}

.custom--card .card-header .card-btn a {
    font-size: 12px;
    padding: 5px 15px;
    font-weight: 600;
}

.custom--card .card-header .card-btn a i {
    margin-right: 5px;
}

.custom--card .card-body {
    padding: 20px 30px;
}

@media only screen and (max-width: 991px) {
    .custom--card .card-body {
        padding: 20px;
    }
}

.custom--card .card-body p {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
}

.custom--card .card-body .card-form-wrapper .form--control {
    font-size: 12px;
    border: 1px solid rgba(57, 57, 57, 0.2);
    background: #ffffff;
    font-size: 13px;
    height: 40px;
    color: #464948;
    font-weight: 600;
}

.custom--card .card-body .card-form-wrapper .form--control::-webkit-input-placeholder {
    color: #464948;
}

.custom--card .card-body .card-form-wrapper .form--control::-moz-placeholder {
    color: #464948;
}

.custom--card .card-body .card-form-wrapper .form--control:-ms-input-placeholder {
    color: #464948;
}

.custom--card .card-body .card-form-wrapper .form--control::-ms-input-placeholder {
    color: #464948;
}

.custom--card .card-body .card-form-wrapper .form--control::placeholder {
    color: #464948;
}

.custom--card .card-body .card-form-wrapper label {
    font-weight: 700;
    margin-bottom: 5px;
}

.custom--card .card-body .card-form-wrapper textarea {
    height: auto;
    min-height: 120px;
    color: #464948;
}

.custom--card .card-body .card-form-wrapper .submit-btn {
    margin-top: 10px;
    padding: 12px 30px;
}

.preloader {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2e556a;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.preloader .loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    margin-left: -60px;
    margin-top: -60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preloader .loader-circle img {
    width: 65%;
    height: 65%;
    -o-object-fit: contain;
    object-fit: contain;
}

.preloader .loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    -webkit-animation: rotate 1.2s infinite linear;
    animation: rotate 1.2s infinite linear;
}

.preloader .loader-line-mask .loader-line {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader .custom-container {
    max-width: 1630px;
}

.preloader .row>* {
    position: relative;
}

@media (min-width: 1480px) {
    .preloader .col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 575px) {
    .preloader .col-xs-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }
}

@media only screen and (max-width: 575px) and (max-width: 400px) {
    .preloader .col-xs-6 {
        width: 100%;
    }
}

.preloader ::-moz-selection {
    background-color: #4f4bcb;
    color: #fff;
}

.preloader ::selection {
    background-color: #4f4bcb;
    color: #fff;
}

/*-------------------------------------------------
      [ ## Heading ]
  */
h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    line-height: 1.3em;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    font-family: "Open Sans", sans-serif;
    font-weight: 900;
}

h1 {
    font-size: 50px;
}

@media only screen and (max-width: 991px) {
    h1 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    h1 {
        font-size: 30px;
    }
}

h2 {
    font-size: 30px;
}

@media only screen and (max-width: 991px) {
    h2 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 24px;
    }
}

h3 {
    font-size: 20px;
}

@media only screen and (max-width: 991px) {
    h3 {
        font-size: 18px;
    }
}

h4 {
    font-size: 18px;
}

@media only screen and (max-width: 991px) {
    h4 {
        font-size: 16px;
    }
}

h5 {
    font-size: 16px;
}

@media only screen and (max-width: 575px) {
    h5 {
        font-size: 14px;
    }
}

h6 {
    font-size: 14px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
    color: inherit;
    text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover {
    color: inherit;
    text-decoration: none;
}

.section-header {
    margin-bottom: 40px;
    position: relative;
}

@media only screen and (max-width: 991px) {
    .section-header {
        margin-bottom: 30px;
    }
}

.section-header .section-sub-title {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #f7f4ff;
    border-radius: 5px;
    color: #A52A2A;
    margin-bottom: 20px;
    font-weight: 600;
}

.section-header .section-title {
    margin-bottom: 0;
    text-transform: capitalize;
}

.section-header .section-title span {
    color: #A52A2A;
}

.section-header p {
    margin-top: 10px;
}

/*--------------------------------------------------------------
  # Components
  --------------------------------------------------------------*/
/*-------------------------------------------------
      [ ## Buttons ]
  */
input[type=submit]:hover {
    color: #ffffff;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
    cursor: pointer;
    -webkit-appearance: button;
}

button:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus {
    outline: none;
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.btn {
    -webkit-transition: all 0.3s ease 0.02s;
    transition: all 0.3s ease 0.02s;
}

.btn:active,
.btn:focus {
    -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.125) inset;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.125) inset;
}

.btn {
    border: 0px solid;
    border-radius: 0px;
    font-weight: 500;
    font-size: 16px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
    .btn {
        font-size: 14px;
    }
}

.btn-rounded {
    border-radius: 3px;
}

.btn-capsule {
    border-radius: 100px;
}

/*-------------------------------------------------
      [ ## custom btn ]
  */
.btn--primary {
    background-color: #7367f0;
}

.btn--secondary {
    background-color: #868e96;
}

.btn--success {
    background-color: #28c76f;
}

.btn--danger {
    background-color: #ea5455;
}

.btn--warning {
    background-color: #ff9f43;
}

.btn--info {
    background-color: #1e9ff2;
}

.btn--dark {
    background-color: #10163a;
}

// .badge--primary {
//     background-color: #7367f0;
// }

// .badge--secondary {
//     background-color: #868e96;
// }

// .badge--success {
//     background-color: #28c76f;
// }

// .badge--danger {
//     background-color: #ea5455;
// }

// .badge--warning {
//     background-color: #ff9f43;
// }

// .badge--info {
//     background-color: #1e9ff2;
// }

// .badge--dark {
//     background-color: #10163a;
// }

.border--primary {
    border: 1px solid #7367f0;
}

.border--secondary {
    border: 1px solid #868e96;
}

.border--success {
    border: 1px solid #28c76f;
}

.border--danger {
    border: 1px solid #ea5455;
}

.border--warning {
    border: 1px solid #ff9f43;
}

.border--info {
    border: 1px solid #1e9ff2;
}

.border--dark {
    border: 1px solid #10163a;
}

// .badge {
//     font-weight: 600;
//     border-radius: 999px;
//     padding: 5px 20px 7px 20px;
//     font-size: 11px;
// }

.btn--base {
    position: relative;
    background: #A52A2A;
    border: 1px solid #A52A2A;
    border-radius: 999px;
    color: #ffffff;
    padding: 12px 30px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.btn--base.active {
    background: transparent;
    color: #A52A2A;
}

.btn--base.active:focus,
.btn--base.active:hover {
    color: #ffffff;
    background: #A52A2A;
}

.btn--base:focus,
.btn--base:hover {
    color: #ffffff;
    -webkit-box-shadow: 0 10px 20px rgba(114, 62, 235, 0.4);
    box-shadow: 0 10px 20px rgba(114, 62, 235, 0.4);
}

/*-------------------------------------------------
      [ ## Fields ]
  */
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus {
    outline: none;
}

input,
textarea {
    padding: 12px 20px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #000000;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #000000;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #000000;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: #000000;
}

input::placeholder,
textarea::placeholder {
    color: #000000;
}

textarea {
    display: block;
    width: 100%;
    display: block;
    min-height: 200px;
}

input,
select,
textarea {
    border: 1px solid rgba(57, 57, 57, 0.2);
    vertical-align: baseline;
    font-size: 100%;
    color: #000000;
}

label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}

/*-------------------------------------------------
      [ ## Forms ]
  */
select {
    outline: none;
    cursor: pointer;
}

option {
    color: #000000;
}

.input-group {
    margin-bottom: 15px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.input-group input {
    border-radius: 5px 0 0 5px;
    border: 1px solid rgba(107, 124, 147, 0.15);
    color: #6b7c93;
    height: 45px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: rgba(114, 62, 235, 0.2);
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
}

.input-group-append input {
    border-radius: 5px 0 0 5px;
    border: 1px solid rgba(57, 57, 57, 0.2);
    border-right: none;
    background-color: #ffffff;
}

.input-group-text {
    border: none;
    font-size: 14px;
    background: #eff0f0;
    color: #000000;
    height: 45px;
    border-radius: 0 5px 5px 0;
    font-weight: 500;
}

.custom-check-group {
    display: block;
    margin-bottom: 12px;
}

.custom-check-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.custom-check-group input:checked+label::before {
    background-color: #A52A2A;
    border: 1px solid #A52A2A;
}

.custom-check-group input:checked+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-check-group label {
    position: relative;
    cursor: pointer;
}

.custom-check-group label::before {
    content: "";
    -webkit-appearance: none;
    background: #ffffff;
    border: 1px solid rgba(57, 57, 57, 0.2);
    border-radius: 3px;
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    top: -2px;
}

.form-group {
    margin-bottom: 20px;
}

.form-control {
    border: 1px solid rgba(57, 57, 57, 0.2);
    font-size: 14px;
    height: 50px;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(57, 57, 57, 0.2);
}

.form--control {
    background-color: #ffffff;
    border: 1px solid rgba(153, 153, 153, 0.2666666667);
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 50px;
    font-size: 14px;
    color: #464948;
    border-radius: 999px;
    padding: 10px 25px;
    width: 100%;
}

.form--control:focus {
    background-color: transparent;
    border: 1px solid rgba(57, 57, 57, 0.2);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #464948;
}

.form--control::-webkit-input-placeholder {
    color: #464948;
}

.form--control::-moz-placeholder {
    color: #464948;
}

.form--control:-ms-input-placeholder {
    color: #464948;
}

.form--control::-ms-input-placeholder {
    color: #464948;
}

.form--control::placeholder {
    color: #464948;
}

.radio-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: -3px -7px;
}

.radio-wrapper .radio-item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 3px 7px;
}

.radio-wrapper .radio-item img {
    width: 60px;
    margin-right: 5px;
}

.radio-item [type=radio]:checked {
    position: absolute;
    left: -9999px;
}

.radio-item [type=radio]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.radio-item [type=radio]:checked+label {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #A52A2A;
    background-color: #A52A2A;
    border-radius: 8px;
    padding: 20px 25px;
    display: inline-block;
    color: #ffffff;
    width: 100%;
    text-align: center;
}

.radio-item [type=radio]:not(:checked)+label {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 20px 25px;
    display: inline-block;
    color: #464948;
    width: 100%;
    text-align: center;
}

.select2-container {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    width: 100%;
}

.selection {
    width: 100%;
}

.select2-selection--single {
    width: 100%;
    height: 45px !important;
    outline: none;
    background: transparent !important;
    font-size: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 10px 10px;
    border-radius: 5px;
    vertical-align: top;
    display: inline-block;
    border: 1px solid rgba(57, 57, 57, 0.2) !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.select2-selection--single span {
    margin: 0;
    width: 100%;
    color: #464948;
    line-height: initial;
    font-weight: 600;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: auto;
    position: absolute;
    top: 22px;
    right: 7px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #464948 transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #464948 transparent;
}

.submit-btn {
    padding: 12px 20px;
    color: #ffffff;
    background: #A52A2A;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 991px) {
    .submit-btn {
        padding: 10px 20px;
    }
}

/*-------------------------------------------------
      [ ## Overlay Element ]
  */
.bg_img {
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
}

.bg_img-2 {
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover;
}

.bg-fixed {
    background-attachment: fixed;
}

.bg-overlay-base {
    position: relative;
}

.bg-overlay-base:before {
    content: "";
    position: absolute;
    background: #A52A2A;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
}

.bg-overlay-base>div {
    position: relative;
    z-index: 2;
}

.bg-overlay-base-2 {
    position: relative;
}

.bg-overlay-base-2:before {
    content: "";
    position: absolute;
    background: #2e556a;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
}

.bg-overlay-base-2>div {
    position: relative;
    z-index: 2;
}

@-webkit-keyframes scroll-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes scroll-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes scale {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }

    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@-webkit-keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-one {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

@keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
    }
}

@-webkit-keyframes wave {
    0% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg);
    }

    100% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }
}

@keyframes wave {
    0% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg);
    }

    100% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/*--------------------------------------------------------------
  # Layout
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## Header ]
  --------------------------------------------------------------*/
.custom-container {
    max-width: 1400px;
}

.row>* {
    position: relative;
}

@media (min-width: 1480px) {
    .col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media (max-width: 575px) {
    .col-xs-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%;
    }
}

@media only screen and (max-width: 575px) and (max-width: 400px) {
    .col-xs-6 {
        width: 100%;
    }
}

::-moz-selection {
    background-color: #A52A2A;
    color: #ffffff;
}

::selection {
    background-color: #A52A2A;
    color: #ffffff;
}

// .top-notice {
//     background-image: url(../images/element/element-2.html);
//     background-size: cover;
//     background-position: top;
//     padding: 10px 15px 50px;
//     text-align: center;
// }

.top-notice .content .btn {
    border-radius: 5px;
    font-weight: 500;
}

.fs-10px {
    font-size: 10px;
}

.op-6 {
    opacity: 0.6;
}

/*-------------------------------------------------
    [ ## Header ]
  */
.header-section {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
}

@media only screen and (max-width: 1400px) {
    .header-section .header-bottom-area .container {
        max-width: 100%;
    }
}

.header {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    z-index: 999;
    -webkit-transition: all ease 1s;
    transition: all ease 1s;
}

.header.active {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    position: fixed;
    top: 0;
    left: 0;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.header-section.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    margin-top: 0;
}

.header-section.header-fixed .header-top-area {
    display: none !important;
}

.header-section.header-fixed .header-bottom-area {
    -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.header-bottom-area {
    position: relative;
    background: #ffffff;
}

@media only screen and (max-width: 991px) {
    .header-bottom-area {
        padding: 10px 0;
        -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    }
}

.header-bottom-area .navbar-expand-lg {
    background-color: transparent;
    padding: 0 !important;
}

@media (max-width: 991px) {
    .header-bottom-area .navbar-collapse {
        background-color: transparent !important;
        padding: 0 !important;
    }

    .header-bottom-area .navbar-collapse .main-menu {
        padding: 15px 0;
    }

    .header-bottom-area .menu_has_children .sub-menu {
        display: none !important;
    }

    .header-bottom-area .menu_has_children .sub-menu li {
        width: 100%;
    }

    .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu,
    .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.header-bottom-area .navbar-collapse .main-menu li:last-child .sub-menu {
    left: auto;
    right: 0;
}

.header-bottom-area .navbar-collapse .main-menu {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .header-bottom-area .navbar-collapse .main-menu {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.header-bottom-area .navbar-collapse .main-menu li {
    position: relative;
    padding-right: 35px;
}

@media only screen and (max-width: 1199px) {
    .header-bottom-area .navbar-collapse .main-menu li {
        padding-right: 22px !important;
    }
}

.header-bottom-area .navbar-collapse .main-menu li:last-child {
    padding-right: 0;
    margin-right: 0;
}

.header-bottom-area .navbar-collapse .main-menu li:hover .sub-menu {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.header-bottom-area .navbar-collapse .main-menu li.menu_has_children {
    position: relative;
}

.header-bottom-area .navbar-collapse .main-menu li.menu_has_children i {
    font-size: 12px;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .header-bottom-area .navbar-collapse .main-menu li.menu_has_children::before {
        top: 12px;
        right: 15px;
    }
}

.header-bottom-area .navbar-collapse .main-menu li a {
    position: relative;
    padding: 33px 0;
    font-weight: 600 !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000000;
    text-transform: uppercase;
    display: inline-block;
    font-size: 15px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 999;
}

.header-bottom-area .navbar-collapse .main-menu li a:hover,
.header-bottom-area .navbar-collapse .main-menu li a.active {
    color: #A52A2A;
}

.header-bottom-area .navbar-collapse .main-menu li a:hover::before,
.header-bottom-area .navbar-collapse .main-menu li a.active::before {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
}

.header-bottom-area .header-section.header-fixed .navbar-collapse .main-menu li a {
    padding: 30px 18px 30px 0;
}

@media (max-width: 991px) {
    .header-bottom-area .navbar-collapse .main-menu li a {
        padding: 12px 15px !important;
    }
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu {
    position: absolute;
    top: 90px;
    left: 0px !important;
    border-top: 5px solid #A52A2A;
    width: 180px;
    background-color: #ffffff;
    -webkit-box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
    box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
    border-radius: 3px;
    display: block;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 10;
}

@media only screen and (max-width: 991px) {
    .header-bottom-area .navbar-collapse .main-menu li .sub-menu {
        width: 180px;
    }
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu li {
    margin-right: 0;
    padding-right: 0;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    position: relative;
    z-index: 2;
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu li:last-child {
    border-bottom: 0px solid;
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu li:hover a {
    color: #ffffff;
    background-color: #A52A2A;
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu li a {
    width: 100%;
    font-size: 16px;
    padding: 8px 15px;
    display: block;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.header-bottom-area .navbar-collapse .main-menu li .sub-menu li a::before {
    display: none;
}

.header-bottom-area .header-section.header-fixed .navbar-collapse .main-menu li .sub-menu li a {
    padding: 8px 20px;
}

@media (max-width: 991px) {
    .header-bottom-area .navbar-collapse .main-menu li .sub-menu {
        position: initial;
        opacity: 1;
        visibility: visible;
        display: none;
        -webkit-transition: none;
        transition: none;
    }
}

.header-bottom-area .navbar-collapse .main-menu li:last-child .sub-menu {
    left: auto;
    right: 0;
}

.site-logo {
    max-width: 280px;
}

@media only screen and (max-width: 991px) {
    .site-logo {
        max-width: 240px;
    }
}

.header-action {
    margin: -10px;
}

.header-action a {
    margin: 10px;
}

.header-action .header-account-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1333333333);
    border-radius: 50%;
    font-size: 18px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.header-action .header-account-btn:hover {
    background-color: #f6f8fe;
    border: 1px solid #f6f8fe;
}

.language-select {
    background: transparent;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
}

@media only screen and (max-width: 991px) {
    .language-select {
        margin-left: 12px;
    }
}

@media only screen and (max-width: 991px) {
    .header-link {
        display: none;
    }
}

.header-link a {
    color: #ffffff;
}

.header-link a i {
    color: #A52A2A;
    margin-right: 5px;
    font-size: 16px;
}

.header-link a+a {
    margin-left: 20px;
}

.header-social li {
    display: inline-block;
}

.header-social li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    background: transparent;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 14px;
    border-radius: 50%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.header-social li a:hover,
.header-social li a.active {
    background: #A52A2A;
    color: #ffffff;
}

.header-social li a.facebook {
    background-color: #0d6efd;
}

.header-social li a.instagram {
    background-color: #dc3545;
}

.header-social li+li {
    margin-left: 5px;
}

.navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-toggler span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    background-color: #A52A2A;
    color: #ffffff;
    font-size: 18px;
}

/*--------------------------------------------------------------
      [ ## Introduction ]
  --------------------------------------------------------------*/
.banner {
    position: relative;
    padding-top: 120px;
    padding-bottom: 200px;
    overflow: hidden;
}

@media only screen and (max-width: 435px) {
    .banner {
        padding-top: 30px;
        padding-bottom: 120px;
    }
}

.banner .top {
    background-color: #A52A2A;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.banner .top h3 {
    color: #ffffff;
    text-align: center;
    font-size: 26px;
}

.banner .top p {
    color: #ffffff;
    text-align: center;
    font-size: 18px;
}

.banner .banner-element {
    position: absolute;
    left: 10%;
    top: 2%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-animation: rotate-center 200s linear infinite both;
    animation: rotate-center 200s linear infinite both;
}

.banner .banner-content .title {
    margin-bottom: 15px;
    position: relative;
    z-index: 100;
    font-size: 55px;
    font-weight: 700;
    color: #464948;
}

@media only screen and (max-width: 768px) {
    .banner .banner-content .title {
        font-size: 35px;
    }
}

@media screen and (width: 1024px) {
    .banner .banner-content .title {
        font-size: 45px;
    }
}

.banner .banner-content .btn--base {
    position: relative;
    z-index: 100;
}

.banner .banner-content p {
    font-size: 16px;
    color: #464948;
    line-height: 2em;
    padding-bottom: 30px;
    position: relative;
    z-index: 100;
    width: 90%;
}

@media only screen and (max-width: 575px) {
    .banner .banner-content p {
        width: 100%;
        font-size: 14px;
    }
}

.banner .banner-form {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 40px -1px rgba(198, 195, 195, 0.57);
    box-shadow: 0px 0px 40px -1px rgba(198, 195, 195, 0.57);
}

.banner .banner-form h3 {
    font-weight: 500;
}

.banner .banner-form .form-control {
    border: 1px solid gray;
}

.banner .banner-thumb img {
    width: 90%;
    height: auto;
}

.banner-content {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
}

@media only screen and (max-width: 435px) {
    .banner-content {
        padding-top: 30px;
        padding-bottom: 80px;
    }
}

.banner-content .title {
    margin-bottom: 15px;
    position: relative;
    z-index: 100;
    font-size: 60px;
    font-weight: 700;
    color: #464948;
}

@media only screen and (max-width: 768px) {
    .banner-content .title {
        font-size: 35px;
    }
}

@media screen and (width: 1024px) {
    .banner-content .title {
        font-size: 45px;
    }
}

.banner-content p {
    font-size: 16px;
    color: #464948;
    line-height: 2em;
    padding-bottom: 30px;
    position: relative;
    z-index: 100;
    width: 90%;
}

@media only screen and (max-width: 575px) {
    .banner-content p {
        width: 100%;
        font-size: 14px;
    }
}

/*--------------------------------------------------------------
      [ ## Footer ]
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Footer-block
  --------------------------------------------------------------*/
.footer-section {
    background: #A52A2A;
    position: relative;
}

.footer-section .single-cta i {
    color: #A52A2A;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.footer-section .cta-text {
    padding-left: 15px;
    display: inline-block;
}

.footer-section .cta-text h4 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.footer-section .cta-text span {
    color: #757575;
    font-size: 15px;
}

.footer-section .footer-content {
    position: relative;
    z-index: 2;
}

.footer-section .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-section .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #ffffff;
    line-height: 28px;
}

.footer-section .footer-social-icon span {
    color: #ffffff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.footer-section .footer-social-icon a {
    color: #ffffff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-section .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.footer-section .facebook-bg {
    background: #3b5998;
}

.footer-section .twitter-bg {
    background: #55acee;
}

.footer-section .instagram-bg {
    background: #f70068;
}

.footer-section .linkedin-bg {
    background: #0077b5;
}

.footer-section .footer-widget-heading h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-section .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ffffff;
}

.footer-section .footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-section .footer-widget ul li a:hover {
    color: #ffffff;
}

.footer-section .footer-widget ul li a {
    color: #ffffff;
    text-transform: capitalize;
}

.footer-section .subscribe-form {
    position: relative;
    overflow: hidden;
}

.footer-section .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #000000;
    border-radius: 999px;
}

.footer-section .subscribe-form button,
.footer-section .subscribe-form input[type=button],
.footer-section .subscribe-form input[type=reset],
.footer-section .subscribe-form input[type=submit] {
    position: absolute;
    right: 0;
    background: #2e556a;
    padding: 13px 20px;
    top: 0;
    border-radius: 0 999px 999px 0;
}

.footer-section .subscribe-form button i,
.footer-section .subscribe-form input[type=button] i,
.footer-section .subscribe-form input[type=reset] i,
.footer-section .subscribe-form input[type=submit] i {
    color: #ffffff;
    font-size: 22px;
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
}

.footer-section .copyright-area {
    background: #A52A2A;
    padding: 15px 0;
}

.footer-section .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #ffffff;
}

.footer-section .copyright-text p a {
    color: #ffffff;
    font-weight: 600;
}

.footer-section .footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-section .footer-menu li:hover a {
    color: #ffffff;
}

.footer-section .footer-menu li a {
    font-size: 14px;
    color: #ffffff;
}

/*--------------------------------------------------------------
  # Pages
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## team ]
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## Testimonial ]
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## Sections ]
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## Sections ]
  --------------------------------------------------------------*/
.features {
    overflow: hidden;
}

.features .card {
    border-radius: 10px;
    color: #A52A2A;
    border: none !important;
    padding: 40px 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.features .card .thumb {
    margin-right: 13px;
}

.features .card .thumb img {
    width: 15rem;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.features .card i {
    font-size: 55px;
    margin-bottom: 20px;
}

.features .card h3 {
    color: #A52A2A;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 700;
}

.features .card p {
    color: gray;
    font-size: 13px;
    margin-top: 10px;
}

.features .card:hover {
    background-color: #f6f8fe;
    color: #ffffff;
}

.features .card:hover h3 {
    color: #A52A2A;
}

.how-it-work {
    overflow: hidden;
}

.how-it-work .text-content h3 {
    text-align: start;
    font-size: 35px;
}

.how-it-work .text-content h4 {
    text-align: start;
}

.how-it-work .bottom-card .img1 {
    width: 50px;
}

.how-it-work .bottom-card .img2 {
    width: 40px;
}

.how-it-work .bottom-card img {
    width: 3rem;
    height: auto;
    margin-bottom: 5px;
}

.how-it-work .bottom-card h3 {
    padding: 10px 0;
    color: #464948;
}

.agents {
    overflow: hidden;
}

.agents .text-content h3 {
    text-align: start;
    font-size: 35px;
}

.agents .text-content h4 {
    text-align: start;
}

.countries {
    overflow: hidden;
    background-color: #A52A2A;
}

.countries .container-c {
    width: 98%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.countries .top-content h3 {
    color: #ffffff;
    font-size: 22px;
}

.countries .text-content h3,
.countries .text-content h4 {
    color: #ffffff;
}

.countries .c-top h3 {
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.countries .card {
    border-radius: 10px;
    color: #A52A2A;
    border: none !important;
    padding: 12px 10px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.countries .card .thumb {
    margin-right: 8px;
}

.countries .card .thumb img {
    width: 2rem;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
}

.countries .card h3 {
    color: #A52A2A;
    font-size: 12px;
    font-weight: 700;
}

.countries .card p {
    color: #000000;
    font-size: 10px;
    margin-top: 10px;
    font-weight: 600;
}

.client {
    overflow: hidden;
}

.client .client-slider .card {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 35px -1px rgba(219, 219, 219, 0.57);
    box-shadow: 0px 0px 35px -1px rgba(219, 219, 219, 0.57);
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: none;
    border-radius: 10px;
}

.client .client-slider .client-thumb img {
    width: 60px;
    border-radius: 999px;
}

.client .client-slider .client-content p {
    margin-top: 15px;
}

.client .swiper-container-horizontal>.swiper-pagination-bullets,
.client .swiper-pagination-custom,
.client .swiper-pagination-fraction {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.client .content h3 {
    font-size: 40px;
    text-align: center;
    color: #151d2a;
    text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
    .client .content h3 {
        font-size: 30px !important;
    }
}

.client .content h3 span {
    color: #A52A2A;
}

.client .content p {
    margin: 20px 0;
    font-size: 18px;
}

.why-choose-us {
    overflow: hidden;
}

.why-choose-us .text-content h3 {
    text-align: start;
    font-size: 35px;
}

.why-choose-us .text-content h4 {
    text-align: start;
}

.why-choose-us img {
    width: 90%;
}

.app {
    overflow: hidden;
    background-color: #f6f8fe;
}

.app .text-content h3 {
    text-align: start;
    font-size: 35px;
}

.app .text-content h4 {
    text-align: start;
}

.app i {
    color: #ffffff;
}

.app .btn--base {
    font-size: 14px;
}

.brand {
    overflow: hidden;
}

.brand img {
    width: 120px;
    height: auto;
}

.info-card {
    overflow: hidden;
}

.info-card .card {
    border-radius: 10px;
    border: none !important;
    padding: 30px;
    border: 2px solid #A52A2A !important;
}

.info-card .card .icon i {
    font-size: 40px;
    padding: 15px 15px;
    background-color: #A52A2A;
    color: #ffffff;
    margin-right: 20px;
    border-radius: 8px;
}

.info-card .card .content h3 {
    color: #2e556a;
    font-size: 24;
}

.contact {
    overflow: hidden;
}

.contact .thumb-right img {
    width: 33rem;
}

.blog-details {
    overflow: hidden;
}

.blog-details .right-content img {
    width: 50rem;
    height: auto;
    border-radius: 20px;
}

.blog-details .right-content h3 {
    margin-top: 15px;
    font-size: 22px;
    color: #2e556a;
}

.blog-details .right-content .details {
    margin-top: 20px;
}

.blog-details .right-content .details p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.blog-details .right-content .hr {
    border-bottom: 1px solid #A52A2A;
    margin-top: 30px;
}

.blog-details .right-content .tag {
    margin-top: 20px;
}

.blog-details .right-content .tag a {
    font-size: 14px;
    background-color: #f6f8fe;
    border-radius: 4px;
    color: #000000;
    padding: 5px;
    margin-right: 10px;
    text-decoration: underline;
}

.blog-details .left-content h3 {
    margin-bottom: 10px;
    font-size: 22px;
}

.blog-details .left-content .hr {
    border-bottom: 5px solid #A52A2A;
    width: 60px;
    margin-bottom: 30px;
}

.blog-details .left-content h4 {
    font-size: 17px;
    line-height: 25px;
}

.blog-details .left-content img {
    width: 110px;
    height: auto;
    border-radius: 6px;
}

.privacy {
    -webkit-box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    padding: 30px;
}

.privacy .content h3 {
    margin-bottom: 15px;
}

.privacy .content .head {
    margin-top: 30px;
}

.error {
    padding-bottom: 100px;
}

.error img {
    width: 500px;
    height: auto;
}

.login img {
    width: 95%;
    height: auto;
}

.login .content {
    -webkit-box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    padding: 30px;
    border-radius: 10px;
}

.login .create-acc a {
    color: #A52A2A;
}

.about-whyChoose {
    overflow: hidden;
}

.about-whyChoose .right-img img {
    width: 33rem;
    height: auto;
}

.about-whyChoose .left-content h3 {
    font-size: 40px;
    color: #464948;
    text-transform: capitalize;
    padding-bottom: 25px;
}

@media only screen and (max-width: 768px) {
    .about-whyChoose .left-content h3 {
        font-size: 30px !important;
    }
}

.about-whyChoose .left-content h3 span {
    color: #A52A2A;
}

.about-whyChoose .left-content p {
    padding-bottom: 30px;
    font-size: 17px;
}

.about-whyChoose .left-content .content i {
    font-size: 18px;
    background-color: #A52A2A;
    color: #ffffff;
    padding: 5px;
    border-radius: 999px;
    margin-right: 13px;
}

.about-whyChoose .left-content .content h5 {
    font-size: 18px;
    color: #464948;
    font-weight: 600;
}

/*--------------------------------------------------------------
      [ ## Blog ]
  --------------------------------------------------------------*/
.blog {
    overflow: hidden;
}

.blog .text-content h3 {
    font-size: 40px !important;
    padding-bottom: 15px;
    letter-spacing: 2px;
}

.blog .text-content h3 span {
    color: #A52A2A;
}

@media only screen and (max-width: 571px) {
    .blog .text-content h3 {
        font-size: 30px !important;
    }
}

.blog .text-content p {
    font-size: 17px !important;
}

.blog .text-content .top-title-content {
    color: #A52A2A;
    font-size: 18px !important;
    font-weight: 600;
    letter-spacing: 3px;
}

.blog .card {
    overflow: hidden !important;
    border-radius: 10px !important;
    border: none !important;
    -webkit-box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    box-shadow: 0px 0px 40px -2px rgba(207, 203, 203, 0.57);
    padding: 15px;
}

.blog .card .rating-color {
    color: #fbc634 !important;
}

.blog .card .card-body {
    padding: 25px 0;
}

.blog .card .title:hover {
    color: #A52A2A !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.blog .card img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.blog .card img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.blog .card .icon {
    color: #A52A2A;
}

.blog .card .author-content {
    margin-bottom: 20px;
}

.blog .card .author-content img {
    width: 30px;
    border-radius: 999px;
    margin-right: 10px;
}

.blog .card .link {
    color: #2e556a;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
}

/*--------------------------------------------------------------
      [ ## Dashboard ]
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
      [ ## Sections ]
  --------------------------------------------------------------*/
/*-------------------------------------------------
      [ ### step block ]
  */
.step-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.step-list li {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    position: relative;
}

@media only screen and (max-width: 575px) {
    .step-list li {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        font-size: 14px;
        margin-bottom: 30px;
    }

    .step-list li:nth-child(2)::after {
        display: none;
    }

    .step-list li:nth-child(3)::before {
        display: none;
    }
}

.step-list li span {
    background-color: #ffffff;
    border: 1px solid rgba(57, 57, 57, 0.2);
    color: #464948;
    border-radius: 50%;
    display: block;
    font-size: 16px;
    line-height: 35px;
    margin: 0 auto 25px;
    position: relative;
    height: 35px;
    width: 35px;
}

.step-list li span::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: 0;
    bottom: 0;
    background-color: #f5f5f5;
    border: 1px solid rgba(57, 57, 57, 0.2);
    border-radius: 50%;
    height: 45px;
    width: 45px;
    z-index: -1;
}

.step-list li:not(:first-child):before,
.step-list li:not(:last-child):after {
    content: "";
    position: absolute;
    top: 15px;
    bottom: 0;
    background-color: #f5f5f5;
    border-top: 1px solid rgba(57, 57, 57, 0.2);
    border-bottom: 1px solid rgba(57, 57, 57, 0.2);
    height: 5px;
    width: 45%;
}

.step-list li:not(:last-child):after {
    left: auto;
    right: -10px;
}

.step-list li:not(:first-child):before {
    left: -10px;
    right: 0;
}

/*-------------------------------------------------
      [ ### overview block ]
  */
.overview-section {
    overflow: hidden;
}

.overview-content {
    margin-right: 80px;
}

@media only screen and (max-width: 991px) {
    .overview-content {
        margin-right: 0;
        text-align: center;
    }
}

.overview-content .title {
    margin-bottom: 15px;
}

.overview-content p {
    font-size: 14px;
}

.overview-chart {
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 5px;
    -webkit-animation: shakes 5s linear infinite;
    animation: shakes 5s linear infinite;
}

@media only screen and (max-width: 991px) {
    .overview-chart {
        -webkit-animation: none;
        animation: none;
    }
}

/*-------------------------------------------------
      [ ### plan block ]
  */
.service-icon {
    font-size: 35px;
    margin-bottom: 15px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    text-align: center;
    background-color: #ffffff;
    border-radius: 50%;
    color: #ffffff;
}

.service-content p {
    font-size: 14px;
}

/*-------------------------------------------------
      [ ### subscribe block ]
  */
.call-to-action-section {
    background-color: #A52A2A;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.call-to-action-area {
    padding: 60px 40px;
    position: relative;
    z-index: 2;
}

@media only screen and (max-width: 991px) {
    .call-to-action-area {
        text-align: center;
    }
}

.call-to-action-area .title {
    letter-spacing: 2px;
}

.call-to-action-form input {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 45px;
    padding: 8px 20px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 3px;
    font-size: 12px;
    width: 100%;
}

.call-to-action-form input::-webkit-input-placeholder {
    color: #ffffff;
}

.call-to-action-form input::-moz-placeholder {
    color: #ffffff;
}

.call-to-action-form input:-ms-input-placeholder {
    color: #ffffff;
}

.call-to-action-form input::-ms-input-placeholder {
    color: #ffffff;
}

.call-to-action-form input::placeholder {
    color: #ffffff;
}

.call-to-action-form input:focus {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
}

.call-to-action-form .submit-btn {
    border-radius: 3px;
    padding: 10px 30px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}

/*-------------------------------------------------
      [ ### faq block ]
  */
.faq-thumb {
    position: relative;
    z-index: 2;
}

.faq-thumb::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 37% 63% 60% 40%/34% 46% 54% 66%;
    z-index: -1;
}

.faq-thumb img {
    -webkit-animation: up_down 3s linear infinite;
    animation: up_down 3s linear infinite;
}

@media only screen and (max-width: 575px) {
    .faq-thumb img {
        -webkit-animation: none;
        animation: none;
    }
}

.faq-wrapper {
    margin-bottom: -20px;
}

.faq-wrapper .main-title {
    margin-bottom: 30px;
}

.faq-wrapper .faq-item {
    margin-bottom: 20px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.faq-wrapper .faq-item .faq-title {
    font-size: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
    .faq-wrapper .faq-item .faq-title {
        font-size: 16px;
    }
}

.faq-wrapper .faq-item .faq-title .title {
    width: calc(100% - 40px);
    padding: 12px 20px;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 0;
}

.faq-wrapper .faq-item.open .faq-title {
    background-color: #ffffff;
    color: #ffffff;
}

.faq-wrapper .faq-item.open .right-icon::before {
    background-color: #ffffff;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.faq-wrapper .faq-item.open .right-icon::after {
    background-color: #ffffff;
}

.faq-wrapper .faq-item.active .faq-content {
    display: block;
}

.faq-wrapper .faq-item.active.open .right-icon::before {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
}

.faq-wrapper .faq-content {
    display: none;
    padding: 20px;
}

.faq-wrapper .faq-content p {
    font-size: 14px;
}

.faq-wrapper .right-icon {
    display: block;
    width: 35px;
    height: 35px;
    position: relative;
    right: 8px;
}

.faq-wrapper .right-icon::before,
.faq-wrapper .right-icon::after {
    position: absolute;
    content: "";
    background: #000000;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.faq-wrapper .right-icon::before {
    width: 2px;
    height: 16px;
    top: 50%;
    left: 50%;
}

.faq-wrapper .right-icon::after {
    width: 16px;
    height: 2px;
    top: 50%;
    left: 50%;
}

/*-------------------------------------------------
      [ ### brand block ]
  */
.brand-wrapper {
    overflow: hidden;
}

/*-------------------------------------------------
      [ ### work block ]
  */
.work-thumb {
    position: relative;
    z-index: 2;
}

.work-thumb::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 37% 63% 60% 40%/34% 46% 54% 66%;
    z-index: -1;
}

.work-thumb img {
    -webkit-animation: up_down 3s linear infinite;
    animation: up_down 3s linear infinite;
}

@media only screen and (max-width: 575px) {
    .work-thumb img {
        -webkit-animation: none;
        animation: none;
    }
}

.work-thumb-two {
    -webkit-animation: shakes 3s linear infinite;
    animation: shakes 3s linear infinite;
}

@media only screen and (max-width: 575px) {
    .work-thumb-two {
        -webkit-animation: none;
        animation: none;
    }
}

.work-content .work-content-header {
    margin-bottom: 30px;
}

.work-content .work-content-header p {
    font-size: 14px;
}

@media only screen and (max-width: 575px) {
    .work-content .work-content-header {
        text-align: center;
    }
}

.work-item-wrapper {
    margin-bottom: -30px;
}

.work-item-wrapper .work-item {
    margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
    .work-item-wrapper .work-item {
        display: block !important;
        text-align: center;
    }
}

.work-icon {
    font-size: 35px;
    color: #464948;
}

.work-details {
    width: calc(100% - 40px);
    padding-left: 10px;
}

@media only screen and (max-width: 575px) {
    .work-details {
        width: 100%;
        padding-left: 0;
        padding-top: 15px;
    }
}

.work-details .title {
    font-size: 18px;
}

.work-details p {
    font-size: 14px;
}

.work-statistics-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid rgba(57, 57, 57, 0.2);
    padding-top: 30px;
}

@media only screen and (max-width: 575px) {
    .work-statistics-area {
        display: block;
        margin-bottom: -30px;
    }
}

.statistics-item {
    position: relative;
}

@media only screen and (max-width: 575px) {
    .statistics-item {
        padding-bottom: 30px;
    }
}

.statistics-item::after {
    position: absolute;
    content: "";
    top: 10px;
    right: -40px;
    width: 2px;
    height: 40px;
    background-color: rgba(57, 57, 57, 0.2);
}

@media only screen and (max-width: 575px) {
    .statistics-item::after {
        display: none;
    }
}

.statistics-item:last-child::after {
    display: none;
}

.statistics-item p {
    font-weight: 500;
    font-size: 16px;
}

.odo-area .odo-title {
    display: inline-block;
    font-size: 40px;
    margin-bottom: 0;
    margin-top: -12px;
    color: #464948;
}

@media only screen and (max-width: 991px) {
    .odo-area .odo-title {
        font-size: 30px;
    }
}

/*-------------------------------------------------
      [ ### video block ]
  */
.video-content-area .title {
    margin-bottom: 15px;
}

.video-content-area p {
    font-size: 14px;
}

@media only screen and (max-width: 575px) {
    .video-content-area {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .video-content-area {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 1200px) {
    .video-content-area {
        float: right;
        padding-left: 30px;
    }
}

@media only screen and (min-width: 992px) {
    .video-content-area {
        max-width: 570px;
    }
}

.video-wrapper .video-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-transform: translate(-10%, -15%);
    transform: translate(-10%, -15%);
    color: #ffffff;
    font-size: 23px;
    z-index: 1;
    background-color: #A52A2A;
    border-radius: 50%;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

@media only screen and (max-width: 575px) {
    .video-wrapper .video-icon {
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
    }
}

.video-wrapper .video-icon::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border-radius: 50%;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    -webkit-animation: ripple 1.6s ease-out infinite;
    animation: ripple 1.6s ease-out infinite;
    background-color: #A52A2A;
}

/*-------------------------------------------------
      [ ### account block ]
  */
.account-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 100vh;
}

.account-wrapper {
    position: relative;
    padding: 8% 0 5%;
    z-index: 2;
}

@media only screen and (max-width: 1199px) {
    .account-wrapper {
        padding: 20% 5% 20%;
    }
}

.account-wrapper::after {
    background-color: #A52A2A;
    opacity: 0.95;
    content: "";
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

@media only screen and (min-width: 992px) {
    .account-wrapper {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
}

.account-form-area {
    padding: 50px 80px;
}

@media only screen and (max-width: 1199px) {
    .account-form-area {
        padding: 50px 20px;
    }
}

@media only screen and (min-width: 992px) {
    .account-form-area {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
}

.account-logo-area {
    margin-bottom: 40px;
}

@media only screen and (max-width: 991px) {
    .account-logo-area {
        text-align: center;
    }
}

.account-header {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dadada;
}

@media only screen and (max-width: 991px) {
    .account-header {
        text-align: center;
    }
}

.account-header .title {
    font-size: 24px;
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .account-header .title {
        font-size: 20px;
    }
}

.account-header .sub-title {
    font-size: 16px;
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .account-header .sub-title {
        font-size: 14px;
    }
}

.account-header .sub-title a {
    color: #464948;
}

.account-content-area {
    margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
    .account-content-area {
        margin-bottom: 40px;
    }
}

.account-content-area .title {
    font-size: 35px;
    font-weight: 700;
}

@media only screen and (max-width: 991px) {
    .account-content-area .title {
        font-size: 24px;
    }
}

.account-content-area .sub-title {
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 991px) {
    .account-content-area .sub-title {
        font-size: 16px;
    }
}

/*-------------------------------------------------
      [ ### contact block ]
  */
.contact-wrapper {
    border-radius: 5px;
    overflow: hidden;
}

.contact-form-area {
    background-color: #ffffff;
    padding: 80px 40px;
}

@media only screen and (max-width: 991px) {
    .contact-form-area {
        padding: 40px 20px;
    }
}

.contact-form-area .title {
    font-size: 26px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
    .contact-form-area .title {
        font-size: 22px;
    }
}

.contact-form-area p {
    font-size: 14px;
}

.contact-form-area .contact-form {
    margin-top: 30px;
}

.contact-form-area textarea {
    border-radius: 3px;
    padding: 12px 20px;
    min-height: 200px;
}

.contact-info-item-area {
    height: 100%;
    position: relative;
    padding: 80px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
}

@media only screen and (max-width: 991px) {
    .contact-info-item-area {
        padding: 40px 20px;
    }
}

.contact-info-item-area::after {
    background-color: #A52A2A;
    opacity: 0.95;
    content: "";
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.contact-info-item-area .header-title {
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #ffffff;
}

@media only screen and (max-width: 991px) {
    .contact-info-item-area .header-title {
        font-size: 22px;
    }
}

.contact-info-item-area p {
    font-size: 14px;
    color: #ffffff;
}

.contact-info-icon i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    -webkit-box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    color: #ffffff;
    font-size: 16px;
}

.contact-info-content {
    width: calc(100% - 50px);
    padding-left: 15px;
}

.contact-info-content .title {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
}

@media only screen and (max-width: 991px) {
    .contact-info-content .title {
        font-size: 16px;
    }
}

.contact-info-content p {
    font-size: 14px;
}

@media only screen and (max-width: 991px) {
    .contact-info-content p {
        font-size: 12px;
    }
}

.maps {
    height: 400px;
}

/*-------------------------------------------------
      [ ## Page-wrapper ]
  */
.page-wrapper {
    min-height: 100vh;
}

.sidebar {
    width: 300px;
    background: #ffffff;
    -webkit-box-shadow: 0 5px 40px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 40px rgba(30, 32, 37, 0.12);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media only screen and (max-width: 1627px) {
    .sidebar {
        width: 250px;
    }
}

.sidebar.active {
    width: 80px;
}

.sidebar.active .sidebar__logo .sidebar__main-logo {
    display: none;
}

.sidebar.active .copyright-wrapper {
    margin-bottom: 25px;
}

.sidebar.active .copyright-area {
    display: none;
}

.sidebar.active .copyright-social li+li {
    margin-left: 0;
    margin-top: 8px;
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a::before {
    display: none;
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a .menu-title {
    display: none;
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item:hover .sidebar-submenu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu {
    display: block;
    position: absolute;
    right: -210px;
    top: 0;
    background-color: #A52A2A;
    width: 230px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item a {
    padding-left: 25px;
}

.sidebar.active .sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item a .menu-title {
    display: block;
}

.sidebar.active .sidebar__menu-header {
    font-size: 0;
    margin: 8px 20px;
    padding: 0;
}

.sidebar.active .sidebar__menu-header::before {
    font-family: "Font Awesome 5 Free";
    content: "\f141";
    font-weight: 900;
    font-size: 1rem;
    color: #ffffff;
}

.sidebar.active .sidebar__logo {
    padding: 25px 15px 28px 15px;
}

.sidebar .res-sidebar-close-btn {
    position: absolute;
    top: 0;
    right: -35px;
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    color: #ffffff;
    display: none;
}

@media only screen and (max-width: 991px) {
    .sidebar .res-sidebar-close-btn {
        display: block;
    }
}

.sidebar .res-sidebar-close-btn i {
    font-size: 24px;
}

.sidebar__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
}

.sidebar__menu-wrapper .sidebar__menu {
    margin: 20px 0 !important;
    list-style-type: none;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item {
    position: relative;
    margin-top: 5px;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 4px;
    height: 55px;
    background-color: #A52A2A;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active a {
    background-color: rgba(114, 62, 235, 0.07);
    border-radius: 30px 0 0 30px;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active a::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 15px !important;
    color: #464948 !important;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active a .menu-icon {
    color: #464948;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active a .menu-title {
    color: #464948;
    font-weight: 700;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.active .sidebar-submenu .sidebar-menu-item a {
    background-color: transparent;
    border-radius: 0;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 25px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a:hover {
    color: #ffffff;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a .menu-icon {
    font-size: 26px;
    margin-right: 15px;
    color: #464948;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item a .menu-title {
    color: #464948;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu {
    position: relative;
    display: none;
    z-index: 1;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu.open {
    display: block;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item .nav-link {
    padding-left: 40px;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item .nav-link::before {
    display: none;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item .nav-link .menu-icon {
    font-size: 18px;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item .nav-link .menu-title {
    color: #ffffff;
    font-weight: 400;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item .sidebar-submenu .sidebar-menu-item .nav-link .menu-title:hover {
    color: #ffffff;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.sidebar-dropdown a {
    position: relative;
}

.sidebar__menu-wrapper .sidebar__menu .sidebar-menu-item.sidebar-dropdown a::before {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #ffffff;
    font-family: "Line Awesome Free";
    font-weight: 700;
    content: "\f105";
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sidebar__menu-header {
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    height: 15px;
    letter-spacing: 0.5px;
    margin-bottom: 22px;
    opacity: 0.6;
    padding: 0 15px 0 15px;
    text-transform: uppercase;
    white-space: nowrap;
    margin-top: 25px;
}

.sidebar__logo {
    padding: 15px 15px 15px 15px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.sidebar__logo .sidebar__main-logo {
    max-width: 14rem;
}

.sidebar__logo .sidebar__main-logo img {
    max-width: 50%;
    height: auto;
}

.sidebar__logo-shape {
    display: none;
}

.navbar__left {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.navbar__left button,
.navbar__left input[type=button],
.navbar__left input[type=reset],
.navbar__left input[type=submit] {
    background-color: transparent;
    position: relative;
    font-size: 25px;
    color: #A52A2A;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}

.navbar__action-list {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar__action-list li {
    margin-right: 15px;
}

.navbar__action-list li:last-child {
    margin-right: 0;
}

.navbar__right button,
.navbar__right input[type=button],
.navbar__right input[type=reset],
.navbar__right input[type=submit] {
    position: relative;
    background-color: transparent;
    font-size: 24px;
    border: none;
}

@media only screen and (max-width: 575px) {

    .navbar__right button,
    .navbar__right input[type=button],
    .navbar__right input[type=reset],
    .navbar__right input[type=submit] {
        padding: 0;
    }
}

.navbar-user {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-user .icon {
    padding-left: 7px;
    font-size: 18px;
    color: #000000;
    position: relative;
    top: 3px;
}

@media only screen and (max-width: 575px) {
    .navbar-user .icon {
        display: none;
    }
}

.navbar-user__thumb {
    width: 40px;
}

.navbar-user__thumb img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    -o-object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.navbar-user__info {
    text-align: left;
    padding-left: 15px;
}

@media only screen and (max-width: 575px) {
    .navbar-user__info {
        display: none;
    }
}

.navbar-user__name {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
}

.dropdown-menu.dropdown-menu--sm {
    min-width: 12rem;
    top: 60% !important;
    left: -10px !important;
    background: #ffffff;
}

.dropdown-menu__item {
    border-bottom: 1px solid rgba(57, 57, 57, 0.3);
    color: #464948;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.dropdown-menu__item:last-child {
    border: none;
}

.dropdown-menu__item .dropdown-menu__icon {
    font-size: 20px;
    color: #464948;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.dropdown-menu__item .dropdown-menu__caption {
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.dropdown-menu__item:hover {
    color: #A52A2A;
    background: #ffffff;
}

.dropdown-menu__item:hover .dropdown-menu__icon {
    color: #A52A2A;
}

.chart-area {
    background-color: #ffffff;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-radius: 5px;
    padding: 22px 0 18px;
}

.chart-area .chart-area-header {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 20px 7px 20px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chart-area .chart-area-header .chart-btn {
    font-size: 12px;
    background-color: rgba(78, 194, 240, 0.2);
    color: #4ec2f0;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 600;
    position: relative;
    bottom: 6px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.chart-area .chart-area-header .chart-btn i {
    font-size: 13px;
    padding-right: 5px;
}

.chart-area .chart-area-header .chart-btn:hover {
    background-color: #4ec2f0;
    color: #ffffff;
}

.chart-area .title {
    margin-bottom: 12px;
}

.card-thumb {
    padding: 30px;
}

.profile-thumb-area {
    margin: 0 auto;
}

.profile-thumb-area .image-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
}

.profile-edit .upload {
    display: none;
}

.profile-edit .imgUp {
    padding: 6px 12px 6px 12px;
    border-radius: 25px;
    font-size: 14px;
    color: #ffffff;
    display: initial;
    cursor: pointer;
}

.rank-label {
    margin-top: -22px;
}

.sidebar-mobile-menu {
    color: #A52A2A !important;
    display: none;
}

@media only screen and (max-width: 991px) {
    .sidebar-mobile-menu {
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .navbar__expand {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .sidebar__menu {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .sidebar__inner {
        display: block;
        min-height: auto;
    }
}

@media only screen and (max-width: 991px) {
    .sidebar {
        width: 100%;
        position: relative;
        min-height: 0px;
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .copyright-wrapper {
        display: none;
    }
}

.bottom-nav {
    display: none;
}

@media screen and (max-width: 991px) {
    .bottom-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: fixed;
        bottom: 0;
        z-index: 999;
        width: 100%;
        background: #A52A2A;
    }
}

@media screen and (max-width: 991px) {
    .bottom-nav a {
        width: 100%;
        text-align: center;
        padding: 11px 10px;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
    }
}

.bottom-nav a.active {
    background-color: #161f5e;
}

@media screen and (max-width: 991px) {
    .bottom-nav a.mid {
        background: #A52A2A;
        border-radius: 8px;
        padding: 12px 10px;
        margin-top: -23px;
    }

    .bottom-nav a.mid i {
        font-size: 30px;
    }

    .bottom-nav a.mid p {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .bottom-nav i {
        color: #ffffff;
        font-size: 24px;
    }
}

@media screen and (max-width: 991px) {
    .bottom-nav p {
        font-size: 12px;
        color: #ffffff;
        margin: 6px 0 0;
        line-height: 1;
    }
}

.body-wrapper {
    padding-left: 330px;
    padding-right: 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media only screen and (max-width: 1627px) {
    .body-wrapper {
        padding-left: 280px;
    }
}

@media only screen and (max-width: 991px) {
    .body-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 784px) {
    .body-wrapper {
        padding-bottom: 80px;
    }
}

.body-wrapper.active {
    padding-left: 95px;
}

.body-wrapper .dashboard-title-part .title {
    font-weight: 600;
}

.dashbord-user {
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid transparent;
    background-color: #ffffff;
    position: relative;
    border-radius: 15px;
    padding: 15px;
    z-index: 2;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.dashbord-user .icon {
    background-color: #ffffff;
    border-radius: 999px;
    padding: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    height: 55px;
    width: 55px;
}

.dashbord-user .icon i {
    font-size: 35px;
    color: #000000;
}

.dashbord-user .bottom-content p {
    font-size: 11px;
}

.dashbord-user .icon-bottom {
    background-color: #ccf5e7;
    padding: 2px;
    border-radius: 8px;
    width: 60px;
}

.dashbord-user .icon-bottom i {
    font-size: 12px;
    margin: auto 0;
    padding-right: 4px;
}

.dashbord-user .icon-bottom p {
    font-size: 12px;
}

.dashbord-user .top-content h3 {
    font-size: 13px;
    color: #ffffff;
    font-weight: 500;
}

.dashbord-user .dash-btn {
    position: absolute;
    top: 30px;
    right: 20px;
    padding: 1px 8px;
    background-color: #A52A2A;
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.dashbord-user .title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
}

.dashbord-user .user-count {
    padding-top: 10px;
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.9);
}

.dashbord-user .dashboard-icon-area .dashboard-icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.8);
}

.flat-card-content .user-count {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flat-card-content .user-count i {
    font-size: 42px;
    font-weight: 900;
}

.flat-card-content .user-count h2 {
    padding-top: 10px;
    padding-left: 17px;
}

.flat-card i {
    font-size: 24px;
}

.dashboard-title-part .title {
    margin-bottom: 0;
    color: #000000;
}

@media only screen and (max-width: 575px) {
    .dashboard-title-part .title {
        font-size: 16px;
    }
}

.dashboard-title-part .title a {
    color: #000000;
}

.dashboard-title-part .title span {
    position: relative;
    padding-left: 30px;
}

@media only screen and (max-width: 575px) {
    .dashboard-title-part .title span {
        padding-left: 15px;
    }
}

.dashboard-title-part .title span::before {
    position: absolute;
    content: "\f105";
    top: 0;
    left: 5px;
    width: 100%;
    height: 100%;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #ffffff;
}

@media only screen and (max-width: 575px) {
    .dashboard-title-part .title span::before {
        left: 0;
    }
}

.dashboard-item-area.style-two {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    overflow: hidden;
}

.dashboard-item-area.style-two .dashbord-user {
    border-radius: 0;
    border-right: 1px solid #e5e5e5;
}

.dashboard-item-area.style-two .dashbord-user:hover {
    -webkit-transform: none;
    transform: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 100vh;
    background: #ffffff;
}

// .navbar-wrapper {
//     background: #ffffff;
//     margin-bottom: 30px;
//     margin-left: 300px;
//     -webkit-transition: all 0.5s;
//     transition: all 0.5s;
// }

// @media only screen and (max-width: 1627px) {
//     .navbar-wrapper {
//         margin-left: 250px;
//     }
// }

// @media only screen and (max-width: 991px) {
//     .navbar-wrapper {
//         margin-left: 0;
//     }
// }

// .navbar-wrapper.active {
//     margin-left: 80px;
// }

// .navbar-wrapper .navbar-wrapper-area {
//     padding: 15px 30px;
//     display: -ms-flexbox;
//     display: -webkit-box;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     background-color: #ffffff;
//     margin: 5px 5px;
//     border-radius: 10px;
// }

// @media only screen and (max-width: 991px) {
//     .navbar-wrapper .navbar-wrapper-area {
//         padding: 15px;
//     }
// }

.app-search {
    margin-left: 30px;
}

.app-search input {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 30px;
}

.app-search span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
}

.navbar-right {
    margin-left: auto;
}

.bell-badge {
    color: #ffffff;
    background: #f74f75;
    border-radius: 50px;
    display: block !important;
    font-size: 10px;
    line-height: 1.1;
    padding: 2px 4px !important;
    position: absolute;
    right: 2px;
    top: 0;
    z-index: 9999;
}

.copyright-wrapper {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 25px;
}

.copyright-wrapper .copyright-area {
    text-align: center;
    border: none;
    padding: 15px;
}

.copyright-wrapper .copyright-area p {
    font-size: 14px;
    color: #464948;
}

.copyright-wrapper .copyright-area p a {
    color: #A52A2A;
}

.copyright-wrapper .copyright-social {
    text-align: center;
}

.copyright-wrapper .copyright-social li {
    display: inline-block;
}

.copyright-wrapper .copyright-social li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border: 2px solid rgba(114, 62, 235, 0.3);
    border-radius: 10px;
    font-size: 18px;
    color: #A52A2A;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.copyright-wrapper .copyright-social li a.active,
.copyright-wrapper .copyright-social li a:hover {
    background-color: #A52A2A;
    color: #ffffff;
}

.copyright-wrapper .copyright-social li+li {
    margin-left: 8px;
}

.table-area {
    margin-top: 10px;
}

.table-header {
    margin-bottom: 15px;
}

.table-header .title {
    margin-bottom: 0;
    color: #464948;
    font-weight: 600;
}

.header-title .top-title h3 {
    font-size: 21px;
    font-weight: 500;
    padding-bottom: 20px;
}

.header-title .top-title h3 span {
    color: #A52A2A;
}

.modal .modal-content {
    background-color: #ffffff;
}

.modal .send-add-form .form-area {
    padding: 8px !important;
}

.modal .modal-footer .btn--base {
    padding: 10px 25px;
    font-size: 15px;
    background-color: #A52A2A;
    color: #ffffff;
}

.modal .modal-footer .btn--base:hover {
    background-color: transparent;
    color: #464948;
}

.modal .modal-footer .btn--base2 {
    padding: 9px 22px;
    font-size: 13px;
    margin-right: 15px;
    background-color: transparent;
    border: 2px solid #d43838;
    color: #464948;
}

.modal .modal-footer .btn--base2:hover {
    background-color: #d43838;
    color: #ffffff;
}

.table-content .form-area .refer .label {
    color: #ffffff;
    font-size: 20px;
    padding-bottom: 15px;
}

.table-content .form-area .refer .copy-text {
    position: relative;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.table-content .form-area .refer .copy-text input.text {
    padding: 10px;
    font-size: 18px;
    color: #555;
    border: none;
    outline: none;
}

.table-content .form-area .refer .copy-text button,
.table-content .form-area .refer .copy-text input[type=button],
.table-content .form-area .refer .copy-text input[type=reset],
.table-content .form-area .refer .copy-text input[type=submit] {
    padding: 10px;
    background: #A52A2A;
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
}

.table-content .form-area .refer .copy-text button:active,
.table-content .form-area .refer .copy-text input[type=button]:active,
.table-content .form-area .refer .copy-text input[type=reset]:active,
.table-content .form-area .refer .copy-text input[type=submit]:active {
    background: #A52A2A;
}

.table-content .form-area .refer .copy-text button:before,
.table-content .form-area .refer .copy-text input[type=button]:before,
.table-content .form-area .refer .copy-text input[type=reset]:before,
.table-content .form-area .refer .copy-text input[type=submit]:before {
    content: "Copied";
    position: absolute;
    top: -45px;
    right: 0px;
    background: #A52A2A;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}

.table-content .form-area .refer .copy-text button:after,
.table-content .form-area .refer .copy-text input[type=button]:after,
.table-content .form-area .refer .copy-text input[type=reset]:after,
.table-content .form-area .refer .copy-text input[type=submit]:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #A52A2A;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
}

.table-content .form-area .refer .copy-text.active button:before,
.table-content .form-area .refer .copy-text.active input[type=button]:before,
.table-content .form-area .refer .copy-text.active input[type=reset]:before,
.table-content .form-area .refer .copy-text.active input[type=submit]:before,
.table-content .form-area .refer .copy-text.active button:after,
.table-content .form-area .refer .copy-text.active input[type=button]:after,
.table-content .form-area .refer .copy-text.active input[type=reset]:after,
.table-content .form-area .refer .copy-text.active input[type=submit]:after {
    display: block;
}

.pricing-card {
    background-color: #ffffff !important;
}

.bank-modal .form-control {
    background-color: #f6f8fe;
}

.bank-modal .btn--base {
    padding: 10px 20px;
}

.bank-modal .btn--base:hover {
    color: #464948;
}

.tran-1 {
    background-color: #f7f3ec;
    color: #da9e44 !important;
    padding: 8px;
    font-size: 13px;
    border-radius: 8px;
}

.tran-2 {
    background-color: #e3eaf3;
    color: #A52A2A !important;
    padding: 8px;
    font-size: 13px;
    border-radius: 8px;
}

.s-icon {
    background-color: #A52A2A;
    color: #ffffff !important;
    padding: 5px 8px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
}

.modal-con {
    background-color: #ffffff !important;
}

.modal-con h2 {
    font-size: 25px !important;
}

.modal-con h3 {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    /* Rounded sliders */
}

.switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input:checked+.slider {
    background-color: #A52A2A;
}

.switch input:focus+.slider {
    -webkit-box-shadow: 0 0 1px #A52A2A;
    box-shadow: 0 0 1px #A52A2A;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.table-content .filepond {
    height: 200px;
    margin-bottom: 0;
}

.table-content .filepond--drop-label {
    height: 100%;
    z-index: 10;
}

.table-content .btn--base {
    text-transform: capitalize;
}

.table-content .filepond--list-scroller {
    height: 100% !important;
}

.table-content .filepond--drop-label.filepond--drop-label label {
    color: #6e768e;
}

.table-content .filepond--panel-root {
    border-radius: 5px;
    background-color: #f1f1f1;
    border: 2px dashed #5a5278;
}

.table-content .filepond--root .filepond--credits {
    display: none;
}

.table-content .disbursement {
    background-color: #f6f8fe;
    margin-top: 30px;
    padding: 20px;
}

.table-content .disbursement .bank-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 8px;
}

.table-content .disbursement .bank-card img {
    width: 70px;
    height: auto;
    margin-right: 20px;
}

.table-content .disbursement i {
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    background-color: #A52A2A;
    border-radius: 50%;
    padding: 5px;
}

.table-content .disbursement .user-text .btn--base {
    font-size: 13px;
    padding: 10px 12px;
    text-transform: capitalize;
}

.table-content .disbursement .user-text .btn--base:hover {
    color: #464948;
}

.table-content .card-preview {
    background-color: #f6f8fe;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
}

.table-content .card-preview .card-content {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
}

.table-content .card-preview .card-content h3 {
    font-size: 15px;
    font-weight: 500;
    margin: auto 0;
}

.table-content .chat-system {
    margin: 30px 0px 0 12px;
}

.table-content .chat-system .right-side {
    padding: 20px;
    background-color: #f6f8fe;
}

.table-content .chat-system .right-side .top-profile img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.table-content .chat-system .right-side .top-profile h3 {
    font-size: 16px;
    font-weight: 500;
    color: #343a40;
}

.table-content .chat-system .right-side .top-profile span {
    padding: 6px;
    background-color: #007efb;
    border-radius: 100%;
}

.table-content .chat-system .right-side .contact-profile .chat-scroll {
    height: 450px;
    overflow-y: scroll;
}

.table-content .chat-system .right-side .contact-profile img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.table-content .chat-system .right-side .contact-profile .title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 17px;
    margin-top: 40px;
    margin-bottom: 30px;
    color: rgb(122, 118, 118);
    font-weight: 500;
}

.table-content .chat-system .right-side .contact-profile h3 {
    font-size: 16px;
    margin-right: 20px;
    font-weight: 500;
    color: #343a40;
}

.table-content .chat-system .right-side .contact-profile span {
    padding: 6px;
    background-color: #007efb;
    border-radius: 100%;
}

.table-content .chat-system .right-side .contact-profile .chat-profile {
    margin: 35px 0;
}

.table-content .chat-system .left-side {
    padding: 20px;
    background-color: #f6f8fe;
}

.table-content .chat-system .left-side .top-profile img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.table-content .chat-system .left-side .top-profile h3 {
    font-size: 16px;
    font-weight: 500;
    color: #343a40;
}

.table-content .chat-system .left-side .top-profile span {
    padding: 6px;
    background-color: #007efb;
    border-radius: 100%;
}

.table-content .chat-system .left-side .top-profile .icon i {
    font-size: 27px;
    cursor: pointer;
    color: #343a40;
}

.table-content .chat-system .left-side .chat-area {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
    margin-top: 30px;
}

.table-content .chat-system .left-side .chat-area img {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    margin: 0px 10px;
}

.table-content .chat-system .left-side .chat-area .chat-body {
    background-color: #d5eef7;
    color: #000000;
    padding: 10px;
    margin: 5px;
    width: 60%;
    font-size: 13px;
    border-radius: 5px;
}

.table-content .chat-system .left-side .chat-form input {
    border: 2px solid #ded7e9 !important;
    border-radius: 8px;
}

.table-content .chat-system .left-side .chat-form input::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 16px;
}

.table-content .dCard-1 {
    background-color: #A52A2A;
}

.table-content .card-details {
    padding: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 30px 0;
    height: 35rem;
}

.table-content .card-details .title-top h3 {
    font-size: 18px;
    padding-bottom: 20px;
}

.table-content .card-details-2 {
    height: 45rem;
}

.table-content .main-card-2 {
    height: auto !important;
}

.table-content .main-card {
    padding: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 30px 0;
    height: 27rem;
}

.table-content .main-card .title-top h3 {
    font-size: 18px;
    padding-bottom: 20px;
}

.table-content .main-card .info-btn {
    background-color: #ffffff !important;
    width: 22rem;
    margin-top: 5px;
    border-radius: 3px;
}

.table-content .main-card .info-btn .card-btn {
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.table-content .main-card .flip-card {
    width: 300px;
    height: 188px;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.table-content .main-card .flip-card .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.table-content .main-card .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.table-content .main-card .flip-card-front,
.table-content .main-card .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.table-content .main-card .flip-card-front {
    color: black;
}

.table-content .main-card .flip-card-back {
    color: white;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.table-content .main-card .credit-card-back {
    padding: 0 !important;
}

.table-content .main-card .credit-card-back .code {
    background-color: #464948;
    height: 40px;
    border-radius: 3px;
    width: 100%;
}

.table-content .main-card .credit-card-back .sign {
    padding: 10px 20px 0px 20px !important;
}

.table-content .main-card .credit-card-back .sign h3 {
    background-color: #ffffff;
    padding: 4px;
    width: 100%;
    font-size: 12px;
    border-radius: 3px 0 0 3px;
}

.table-content .main-card .credit-card-back .sign h4 {
    background-color: #f6f8fe;
    width: 4rem;
    padding: 4px;
    font-size: 12px;
    border-radius: 0px 3px 3px 0px;
}

.table-content .main-card .credit-card-back .bottom-text {
    padding: 2px 20px 0px 20px !important;
}

.table-content .main-card .credit-card-back .bottom-text h3 {
    font-size: 11px;
    color: #ffffff;
    font-weight: 500;
}

.table-content .main-card .credit-card-back .bottom-text img {
    width: 30px;
}

.table-content .main-card .credit-card-back .text {
    padding: 15px 0px 0px 20px !important;
}

.table-content .main-card .credit-card-back .text p {
    font-size: 10px;
    padding-bottom: 10px;
}

.table-content .main-card .credit-card {
    background: rgb(199, 138, 29);
    background: -webkit-gradient(linear, left top, right top, from(rgb(199, 138, 29)), color-stop(77%, rgb(54, 88, 102)));
    background: linear-gradient(90deg, rgb(199, 138, 29) 0%, rgb(54, 88, 102) 77%);
    width: 19rem;
    height: 12rem;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
}

.table-content .main-card .credit-card .top-content img {
    width: 50px;
}

.table-content .main-card .credit-card .top-content h3 {
    font-size: 16px;
    color: #ffffff;
}

.table-content .main-card .credit-card .middle-content img {
    width: 30px;
    margin-right: 10px;
}

.table-content .main-card .credit-card .middle-content h3 {
    font-size: 18px;
    margin: auto 0;
    color: #ffffff;
}

.table-content .main-card .credit-card .bottom-content img {
    width: 30px;
    margin-right: 10px;
}

.table-content .main-card .credit-card .bottom-content h3 {
    font-size: 13px;
    font-weight: 500;
    margin: auto 0;
    color: #ffffff;
    text-transform: uppercase;
}

.table-content .main-card .credit-card .bottom-content h4 {
    font-size: 13px;
    font-weight: 500;
    margin: auto 0;
    color: #ffffff;
    text-transform: capitalize;
    padding-top: 5px;
}

.table-content .main-card .credit-card .bottom-content .status h4 {
    font-size: 12px;
}

.table-content .main-card .swiper-container-horizontal>.swiper-pagination-bullets,
.table-content .main-card .swiper-pagination-custom,
.table-content .main-card .swiper-pagination-fraction {
    bottom: 48px;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.table-content .main-card .card-btn {
    padding-top: 30px;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
}

.table-content .main-card .card-btn i {
    background-color: #ffe3b5;
    padding: 10px;
    font-size: 30px;
    border-radius: 999px;
    color: #2e556a;
}

.table-content .main-card .card-btn h3 {
    font-size: 12px;
    padding-top: 7px;
    font-weight: 500;
    letter-spacing: 1px;
}

.table-content .chart {
    margin: 40px 0;
}

.table-content .chart .chart-bg {
    background-color: #f6f8fe;
    padding: 40px;
    border-radius: 10px;
    width: auto;
    height: 27rem;
}

.table-content .chart .chart-bg h3 {
    font-size: 16px;
}

.table-content .card-user {
    background-color: #f6f8fe;
}

.table-content .card-user .avater {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    border: 2px solid #f6f8fe;
}

.table-content .card-user .form-dashboard .form-control {
    background-color: #ffffff !important;
}

.table-content .card-user .form-dashboard select {
    background-color: #ffffff !important;
}

.table-content .card-user .country-select {
    width: 100% !important;
}

.table-content .card-chat {
    background-color: #f6f8fe;
    padding: 25px;
    border-radius: 10px;
}

.table-content .card-chat .chat-area {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
}

.table-content .card-chat .chat-area img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 0px 10px;
}

.table-content .card-chat .chat-area .chat-body {
    background-color: #A52A2A;
    color: #ffffff;
    padding: 10px;
    margin: 5px;
    width: 60%;
    font-size: 12px;
    border-radius: 5px;
}

.table-content .card-chat .chat-form input {
    border: 2px solid #ded7e9 !important;
    border-radius: 10px;
}

.table-content .card-chat .chat-form input::-webkit-input-placeholder {
    font-weight: 600;
    font-size: 17px;
}

.table-content .card-chat .btn--base {
    padding: 13px 14px;
}

.table-content .card-chat .btn--base:hover {
    color: #464948;
}

.table-content .connect {
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    padding: 10px;
    border-radius: 10px;
}

.table-content .connect i {
    color: #ffffff;
    font-size: 35px;
    background-color: #A52A2A;
    padding: 5px;
    border-radius: 10px;
}

.table-content .connect .btn--base {
    padding: 5px 15px !important;
}

.table-content .connect .btn--base2 {
    background-color: #1878ff !important;
}

.table-content .connect .card-icon-1 {
    background-color: #c82450 !important;
}

.table-content .connect .card-icon-2 {
    background-color: #1da1f2;
}

.table-content .connect .card-icon-3 {
    background-color: #0077b5;
}

.table-content .form-select option:hover {
    background-color: #A52A2A !important;
}

.table-content .dashboard-services {
    background-color: #ffffff !important;
    border-radius: 10px;
}

.table-content .dashboard-services .card {
    background-color: #f6f8fe;
}

.table-content .statusline .form-control {
    background-color: #ffffff !important;
    border-radius: 20px !important;
    border: none !important;
}

.table-content .form-area-tab {
    margin-top: 30px;
    background-color: #f6f8fe;
    padding: 20px;
    border-radius: 15px;
}

.table-content .form-area-tab input,
.table-content .form-area-tab textarea {
    border-radius: 8px;
    background-color: #ffffff;
}

.table-content .form-area-tab .file .form-control {
    height: 35px;
}

.table-content .form-area-tab .form-label {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.table-content .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    gap: 8px;
}

@media only screen and (max-width: 450px) {
    .table-content .pagination {
        gap: 1px;
    }
}

.table-content .pagination .pages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 7px;
}

.table-content .pagination .page {
    height: 40px;
    font-size: 16px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
}

.table-content .pagination .page:hover {
    background-color: #A52A2A;
    color: #ffffff;
}

.table-content .pagination .active {
    background-color: #A52A2A;
    color: #ffffff;
}

.table-content .pagination .btn {
    background-color: #ffffff;
    border: 1px solid #A52A2A;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.table-content .pagination .btn:hover {
    background-color: #A52A2A;
    color: #ffffff;
}

.table-content .pagination .btn--icon {
    height: 24px;
    width: 24px;
    stroke: #A52A2A;
}

.table-content .pagination .btn--icon:hover {
    stroke: #ffffff;
}

.table-content .list-card {
    margin-top: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.table-content .list-card .header-title-list h4 {
    font-size: 22px;
}

.table-content .social .card {
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.table-content .social .btn--base {
    padding: 8px 20px !important;
}

.table-content .social .icon {
    background-color: #A52A2A;
    padding: 8px;
    font-size: 30px;
    border-radius: 10px;
    color: #ffffff;
}

.table-content .social-2 .card {
    border-radius: 10px;
}

.table-content .social-2 .btn--base {
    padding: 7px 12px !important;
    border-radius: 10px !important;
}

.table-content .social-2 .btn--base i {
    font-size: 18px;
}

.table-content .social-2 .del {
    background-color: rgb(198, 28, 28);
    border: none !important;
}

.table-content .social-2 .icon {
    background-color: #A52A2A;
    padding: 8px;
    font-size: 30px;
    border-radius: 10px;
    color: #ffffff;
}

.table-content .card {
    border: none !important;
}

.table-content .user-card {
    background: #ffffff;
}

.table-content .user-card p {
    color: #000000;
}

.table-content .card:hover:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.table-content .card-1 {
    border-radius: 10px;
}

.table-content .form-dashboard .form-control {
    height: 42px !important;
    background: gainsboro !important;
}

.table-content .form-dashboard select {
    background-color: gainsboro !important;
    padding: 8px 10px !important;
}

.table-content .form-dashboard button,
.table-content .form-dashboard input[type=button],
.table-content .form-dashboard input[type=reset],
.table-content .form-dashboard input[type=submit] {
    padding: 10px 18px !important;
}

.table-content .table {
    background: -webkit-gradient(linear, left top, right top, from(rgb(125, 125, 141)), color-stop(43%, rgb(65, 51, 51)), to(rgb(31, 55, 59))) !important;
    background: linear-gradient(90deg, rgb(125, 125, 141) 0%, rgb(65, 51, 51) 43%, rgb(31, 55, 59) 100%) !important;
    border: #f6f8fe !important;
}

.table-content .table th,
.table-content td {
    padding: 1rem 1rem !important;
}

.table-content .card-text {
    padding: 10px 15px;
    background-color: #A52A2A;
    color: #ffffff;
    border-radius: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.table-content .send-box-text i {
    padding-right: 20px;
    margin: auto;
    color: #A52A2A;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    font-size: 30px;
}

.table-content .send-box-text h4 {
    font-weight: 600;
    color: #ffffff;
}

.table-content .send-box-text h3 {
    font-weight: 600;
    color: #A52A2A;
    padding-top: 5px;
}

.table-content .rec-box-text i {
    padding-right: 20px;
    margin: auto;
    color: #000000;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    font-size: 30px;
}

.table-content .rec-box-text h4 {
    font-weight: 600;
    color: #000000;
}

.table-content .rec-box-text h3 {
    font-weight: 600;
    color: #000000;
    padding-top: 5px;
}

.table-content .rec-box-text span {
    color: #000000;
}

.custom-table {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
}

.custom-table .status--btn {
    background-color: #ff9f43;
    padding: 4px 5px;
    width: 80px;
    border-radius: 8px;
    font-size: 10px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 500;
}

.custom-table .status--btn-2 {
    background-color: #28c76f;
}

@media only screen and (max-width: 1340px) {
    .custom-table thead {
        display: none;
    }
}

.custom-table thead tr {
    background: #A52A2A;
}

.custom-table thead tr th {
    border: none;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    padding: 15px 30px;
}

.custom-table thead tr th:first-child {
    border-radius: 8px 0 0 8px;
    text-align: left;
}

.custom-table thead tr th:last-child {
    border-radius: 0 8px 8px 0;
    text-align: right;
}

.custom-table tbody tr {
    background: rgb(255, 253, 253);
    -webkit-box-shadow: 2px rgba(56, 65, 74, 0.15);
    box-shadow: 2px rgba(56, 65, 74, 0.15);
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.custom-table tbody tr:hover {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
}

.custom-table tbody tr td {
    border: none;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    padding: 15px 30px;
}

@media only screen and (max-width: 991px) {
    .custom-table tbody tr td {
        padding: 15px 15px;
        font-size: 14px;
    }
}

.custom-table tbody tr td span {
    font-weight: 700;
}

.custom-table tbody tr td:first-child {
    text-align: left;
}

@media only screen and (max-width: 1340px) {
    .custom-table tbody tr td:first-child {
        text-align: right;
    }
}

.custom-table tbody tr td:last-child {
    text-align: right;
}

.custom-table tbody tr td .vote {
    font-size: 10px;
    font-weight: 700;
}

.custom-table tbody tr td .author-info {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom-table tbody tr td .author-info .thumb {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
}

.custom-table tbody tr td .author-info .thumb img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.custom-table tbody tr td .author-info .content {
    padding-left: 15px;
    width: calc(100% - 35px);
}

.custom-table tbody tr td a {
    color: #ffffff;
    padding: 3px 8px;
    border-radius: 3px !important;
}

.custom-table tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}

.custom-table tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}

@media only screen and (max-width: 1340px) {
    .custom-table tbody tr td {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: right;
        border-bottom: 1px solid rgba(57, 57, 57, 0.2);
    }

    .custom-table tbody tr td:last-child {
        border: none;
    }

    .custom-table tbody tr td::before {
        content: attr(data-label);
        margin-right: auto;
        color: #464948;
        width: 50%;
        text-align: left;
    }
}

/*-------------------------------------------------
    [ ## card ]
  */
.custom--card {
    border: none;
    background: #f6f8fe;
    -webkit-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-radius: 5px;
}

.custom--card .btn--base {
    font-size: 13px;
    padding: 14px 14px;
    text-transform: capitalize;
}

.custom--card .btn--base:hover {
    color: #464948;
}

.custom--card .user-text h4 {
    font-size: 30px;
    font-weight: 600;
    color: #464948;
    padding: 25px;
}

.custom--card .card-header {
    background: rgba(70, 73, 72, 0.1);
    padding: 1.2rem 1.8rem;
    border-radius: 5px 5px 0 0;
    border: none;
}

.custom--card .card-header .card-title {
    color: #464948;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 3px;
    font-weight: 600;
}

.custom--card .card-header .card-title i {
    margin-right: 5px;
}

.custom--card .card-header .card-btn a {
    font-size: 12px;
    padding: 5px 15px;
    font-weight: 600;
}

.custom--card .card-header .card-btn a i {
    margin-right: 5px;
}

.custom--card .card-body {
    padding: 20px 30px;
    border-radius: 10px;
}

@media only screen and (max-width: 991px) {
    .custom--card .card-body {
        padding: 20px;
    }
}

.custom--card .card-body .user-text h4 {
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    padding-top: 10px;
}

.custom--card .card-body .card-form-wrapper .form--control {
    border: 1px solid rgba(57, 57, 57, 0.2);
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 400;
    color: #ffffff;
}

.custom--card .card-body .card-form-wrapper .form--control:focus {
    background-color: #ffffff;
}

.custom--card .card-body .card-form-wrapper textarea {
    height: auto;
    min-height: 120px;
    color: #ffffff;
}

.custom--card .card-body .card-form-wrapper .submit-btn {
    margin-top: 10px;
    padding: 12px 30px;
}

.custom--card .card-body .ref-input {
    height: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #464948;
}

.custom--card .card-body .ref-input:focus {
    background-color: #ffffff;
}

.custom--card .card-body .copytext {
    border: none;
    font-size: 18px;
    background-color: #A52A2A;
    color: #ffffff;
    height: 50px;
    border-radius: 0 3px 3px 0;
    font-weight: 500;
}

.send-add-form {
    margin: 0px 0;
}

.send-add-form .form-area {
    background-color: #f6f8fe;
    border-radius: 10px;
    padding: 30px;
}

.send-add-form .form-area .form-control {
    background-color: #ffffff;
    border-radius: 8px;
}

.send-add-form .select-custom {
    background-color: #A52A2A;
    height: 49px;
    border-radius: 0px 5px 5px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.send-add-form .select-custom h3 {
    margin: auto 0;
    color: #ffffff;
    font-size: 17px;
}

.send-add-form .send-add-form h3 {
    color: #464948;
}

.send-add-form .form-control {
    background-color: #f6f8fe;
}

.send-add-form .recipient .form-control {
    background-color: #ffffff !important;
    width: 100%;
}

.send-add-form .date input {
    border-radius: 5px !important;
}

.send-add-form .date .label {
    font-size: 17px;
    padding-bottom: 10px;
    color: #ffffff;
}

.send-add-form .date .custom--btn {
    padding: 13px 20px !important;
}

.send-add-form .table-statement th,
.send-add-form .table-statement td {
    color: #ffffff;
    font-size: 17px;
}

.send-add-form h3 {
    color: #464948;
    font-weight: 500;
}

.send-add-form .top-text h4 {
    font-size: 20px;
    font-weight: 600;
    color: #464948;
    padding-bottom: 30px;
    letter-spacing: 1px;
}

.preview {
    background-color: #f6f8fe;
    padding: 25px;
    border-radius: 8px;
}

.preview h4 {
    color: #464948;
    font-size: 15px;
    font-weight: 500;
}

.preview hr {
    color: #464948;
    padding: 1px;
}

.profile-content-area li {
    color: #ffffff;
}

.nav-pills .nav-link {
    border-radius: 20px !important;
    font-weight: 600;
    background-color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px rgba(0, 46, 99, 0.3);
    box-shadow: 0 3px 5px rgba(0, 46, 99, 0.3);
}

.table-content .acc {
    background-color: #A52A2A;
    border-radius: 8px;
    margin-bottom: 10px;
}

.table-content .acc h4 {
    color: #ffffff;
    padding: 10px 0;
}

.table-content .acc hr {
    margin: 0;
    color: #ffffff;
    padding: 1px;
}

.table-content .refer {
    background-color: #ffffff;
    border-radius: 10px;
}

.table-content .refer h4 {
    padding-bottom: 15px;
}

.table-content .refer #inviteCode.invite-page {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
}

.table-content .refer #inviteCode.invite-page #link {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 1.2em;
    color: #333;
    font-weight: 600;
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    background-color: #fff;
    border: none;
}

.table-content .refer #inviteCode.invite-page #copy {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f8f8f8;
    margin: auto;
}

.table-content .refer #inviteCode.invite-page #copy i {
    display: block;
    color: #A52A2A;
    line-height: 30px;
    position: relative;
}

.table-content .refer #inviteCode.invite-page #copy i::before {
    display: block;
    width: 12px;
    margin: 0 auto;
}

.table-content .refer #inviteCode.invite-page #copy i.copied::after {
    position: absolute;
    top: 0px;
    right: 35px;
    height: 30px;
    line-height: 25px;
    display: block;
    content: "copied";
    font-size: 1.5em;
    padding: 2px 10px;
    color: #fff;
    background-color: #4099ff;
    border-radius: 3px;
    opacity: 1;
    will-change: opacity, transform;
    -webkit-animation: showcopied 1.5s ease;
    animation: showcopied 1.5s ease;
}

.table-content .refer #inviteCode.invite-page #copy:hover {
    cursor: pointer;
    background-color: #dfdfdf;
    -webkit-transition: background-color 0.3s ease-in;
    transition: background-color 0.3s ease-in;
}

@-webkit-keyframes showcopied {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    70% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
    }
}

@keyframes showcopied {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    70% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        opacity: 0;
    }
}

/*-------------------------------------------------
      [ ### music block ]
  */
.season-play-btn {
    display: inline-block;
    cursor: pointer;
}

.music__player {
    position: fixed !important;
    bottom: -100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    z-index: 9999;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.music__player.open {
    bottom: 0;
}

.music {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    background: #080808;
    -webkit-box-shadow: 0 4px 7px rgba(0, 46, 99, 0.3);
    box-shadow: 0 4px 7px rgba(0, 46, 99, 0.3);
}

/* +++ METADATA +++ */
.music__meta {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    margin-right: 60px;
}

.music__title,
.music__description {
    overflow: hidden;
    width: 100%;
    color: #ffffff;
}

.music__title {
    margin: 0;
}

.music__description {
    font-size: 0.8rem;
    color: #ffffff;
}

.music__cover {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.music__image {
    width: 100px;
    height: 100px;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 0;
    margin: 0;
    margin-right: 30px;
}

#music-play {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-image: -webkit-gradient(linear, left top, right top, from(#00f9ef), color-stop(#00e0ff), color-stop(#00c4ff), color-stop(#00a4ff), to(#007efb));
    background-image: linear-gradient(to right, #00f9ef, #00e0ff, #00c4ff, #00a4ff, #007efb);
    border-radius: 50%;
}

#music-play:hover i {
    background: transparent;
    color: #ffffff;
    -webkit-transform: none;
    transform: none;
}

/* +++ UPLOAD +++ */
.music--upload::before,
.music--upload::after {
    display: block;
    position: absolute;
    background: #f9fcff;
}

.music--upload::after {
    width: 100%;
    height: 100%;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 90;
}

.music--upload::before {
    width: 95%;
    height: 90%;
    content: "DROP HERE...";
    top: calc(5% - 0px);
    bottom: calc(5% - 0px);
    left: calc(2.5% - 0px);
    right: calc(2.5% - 0px);
    border: 5px dashed #ffffff;
    border-radius: 9px;
    line-height: 5;
    text-align: center;
    vertical-align: middle;
    font-size: 2rem;
    font-weight: 800;
    color: #002e63;
    z-index: 95;
}

.music__uploader {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: -100%;
    bottom: 100%;
    opacity: 0;
    background: #ffffff;
}

.music__uploader--show {
    display: block;
    top: 0;
    bottom: 0;
    z-index: 99;
}

/* +++ CONTROLLERS +++ */
.music__controller {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.music__btn {
    border: 0;
    outline: 0;
    stroke: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    cursor: pointer;
    background-color: transparent;
}

.music__btn i {
    padding: 12px;
    border-radius: 5px;
    color: #ffffff;
    will-change: text-shadow, box-shadow, transform, background-color, color, padding-right, padding-left;
}

.music__btn--pause i {
    padding-left: 14px;
    padding-right: 12px;
}

.music__btn:hover i {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: #ffffff;
    background-color: #363e46;
}

.music__btn:active i {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

/* +++ MIXINS +++ */
.music__mixin,
.music__volume {
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.music__mixin {
    -webkit-box-align: space-between;
    -ms-flex-align: space-between;
    align-items: space-between;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
}

.music__volume_btn,
.music__shuffle,
.music__repeat,
.music__playlist_open {
    border: 0;
    outline: 0;
    stroke: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    cursor: pointer;
    height: 25px;
    background-color: transparent;
}

.music__volume_btn:hover i,
.music__shuffle:hover i,
.music__repeat:hover i,
.music__playlist_open:hover i {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    background-color: #363e46;
}

.music__volume_btn:active i,
.music__shuffle:active i,
.music__repeat:active i,
.music__playlist_open:active i {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.music__volume_btn i,
.music__shuffle i,
.music__repeat i,
.music__playlist_open i {
    padding: 8px;
    border-radius: 9px;
    color: #ffffff;
    will-change: transform, background-color, color, padding-right, padding-left;
}

.music__volume_btn--on i,
.music__shuffle--on i,
.music__repeat--on i,
.music__playlist_open--on i {
    color: #ffffff;
}

.music__shuffle,
.music__repeat,
.music__playlist_open {
    width: 33.33%;
}

.music__controller-wrapper {
    min-width: 600px;
}

/* +++ SEEK/TIMES +++ */
.music__times {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: space-between;
    -ms-flex-align: space-between;
    align-items: space-between;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    margin-top: 5px !important;
}

.music__duration,
.music__current_time {
    width: 10%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.music__times,
.music__duration,
.music__current_time {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
}

.music__duration {
    text-align: right;
    padding-right: 5px;
}

.music__current_time {
    text-align: left;
    padding-left: 2px;
}

.music__name {
    position: relative;
    top: 7px;
}

.music__seek {
    width: 80%;
    background-color: #add8e6;
}

.music__seek_handle {
    width: var(--seek_listener_percentage);
    display: block;
    background: #ffffff;
}

.music__seek,
.music__seek_handle {
    height: 3px;
    border-radius: 9px;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    will-change: height;
}

.music__seek:hover,
.music__seek:hover .music__seek_handle {
    height: 5px;
}

/** +++ VOLUME +++ **/
.music__volume {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    width: 200px;
}

.music__volume_btn {
    width: 19%;
    -webkit-transform: translate(-7px, -25%);
    transform: translate(-7px, -25%);
}

.music__volume_range {
    width: 70%;
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
    background-color: #add8e6;
}

.music__volume_handle {
    width: var(--volume_listener_percentage);
    display: block;
    background-color: #ffffff;
}

.music__volume_range,
.music__volume_handle {
    height: 3px;
    border-radius: 9px;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

/* +++ PLAYLIST +++ */
.music__playlist {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 100%;
    right: 0;
    z-index: 70;
    display: none;
}

.music__playlist--open,
.music__playlist--on {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    top: 0;
    bottom: 0;
    background: #eeeeee;
}

.music__playlist,
.playlist__track_list {
    overflow: hidden;
}

.playlist__track_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.playlist__close_btn {
    border: 0;
    outline: 0;
    stroke: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    cursor: pointer;
    position: absolute;
    top: 5px;
}

.playlist__close_btn:hover i {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    background-color: #eeeeee;
}

.playlist__close_btn:active i {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.playlist__track_list {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

.playlist__track_list:focus {
    border: 0;
    outline: 0;
    stroke: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

.playlist__track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 90%;
    height: 90px;
    background-color: #f9fcff;
    margin: 9px auto;
    padding: 10px;
    border-radius: 15px;
}

.playlist__track:hover {
    background-color: #e5eff9;
    cursor: pointer;
}

.playlist__track--current {
    background: bisque;
}

.playlist__cover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 0;
    margin: 0;
    width: 70px;
    height: 70px;
    border-radius: 9px;
}

.playlist__meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    padding: 9px;
}

.playlist__title,
.playlist__artist {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.paddings-bt-20 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.padding-t-0 {
    padding-top: 0px !important;
}

#transactionFee,
#amountToPay {
    font-size: 14px !important;
    color: #e9e9e9;
}