form label.required:after {
  color: red !important;
  content: " *" !important;
}

.react-confirm-alert-overlay {
  z-index: 9999;
  background: rgba(255, 255, 255, 0.979) !important;
}

//
.btn_actions {
  width: 100% !important;
}

// react table css

.ReactTable .rt-tbody .ReactTable_actions {
  overflow: visible !important;
}

.ReactTable .-header .rt-th,
.ReactTable .-header .rt-td {
  padding: 0 10px 0 0 !important;
  font-size: 12px;
  line-height: 35px !important;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #2c80ff;
  text-transform: uppercase;
  border-bottom: none;
}

.ReactTable .rt-td:not(.ReactTable_actions) {
  padding-top: 17px !important;
}

.mobile-menu {
  padding: 0px !important;
  padding-left: 20px !important;
}

.cc-card {
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 30px;
  background-size: cover;
  background: url("images/cc_card.jpg") 0 0 no-repeat #42529c;
  color: #fff;
  cursor: pointer;
  top: 0;
}

// transfer nav

.AJ,
.jb,
.kY,
.oP {
  position: relative;
}

.qv {
  width: 100%;
}

.ik .bb {
  background-image: url("images/trans_user.png");
}

.BB,
.bb,
.ol,
.qv {
  position: relative;
}

.bb {
  height: 300px;
  // margin-right: 40px;
  margin-bottom: 40px;
  background: no-repeat right bottom #fff;
  border: 1px solid #edeef2;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.bb::before {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  content: "";
}

.BB {
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  max-width: 380px;
}

.vj {
  margin-bottom: 15px;
  font-size: 150%;
  font-weight: 300;
  color: #8ab657;
}

.pm {
  font-size: 95%;
  color: #a0a3ad;
}

.hH .bb::after,
.hH::after,
.jo .bb::after {
  position: absolute;
  content: "";
}

.hH .bb::after {
  display: block;
  top: 45px;
  right: 0;
  left: 0;
  height: 50px;
  background-color: #f5f5f7;
}

.hH::after {
  display: block;
  width: 220px;
  height: 34px;
  background: url("images/trans_cards.png") no-repeat 0 0;
  background-size: 220px;
  pointer-events: none;
  bottom: 60px;
  right: 60px;
}

.jv .bb {
  background-image: url("images/trans_case.png");
}

.jo .bb {
  background-image: url("images/trans_map.jpg");
}

.jo .bb::after {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.85;
}

// @media (min-width: 1640px){
//   .qv {
//     width: 33.33%;
//   }
// }
// @media (min-width: 1210px){
//   .qv {
//     width: 50%;
//   }
// }

@media (min-width: 769px) {
  .ol {
    min-height: 340px;
  }
  .ol ul {
    margin: 0 -40px -40px 0;
  }
  .ik .bb:hover {
    background-image: url("images/trans_user_green.png");
  }
  .bb:hover .vj {
    color: #fff;
  }
  .bb:hover .pm {
    color: #d5f5b0;
  }
  .bb:hover {
    background-color: #a3cd73;
  }
  .bb:hover .vj {
    color: #fff;
  }
  .bb:hover .pm {
    color: #d5f5b0;
  }
  .hH .bb:hover::after {
    background-color: #abd57b;
  }
  .jv .bb:hover {
    background-image: url("images/trans_case_green.png");
  }
  .jo .bb:hover::after {
    background-color: #9bc66a;
  }
}

// end transfer

.form-step-head {
  border-bottom: 1px solid #e0e8f3 !important;
}

// payments

.hv {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

@media (min-width: 970px) {
  .hv {
    width: 33.33%;
  }
}

@media (min-width: 1180px) {
  .hv {
    width: 25%;
  }
}

@media (min-width: 1390px) {
  .hv {
    width: 20%;
  }
}

@media (min-width: 1600px) {
  .hv {
    width: 16.66%;
  }
}

.cW {
  position: relative;
  margin: 10px;
  border: 1px solid #edeef2;
  border-radius: 6px;
  height: 195px;
  cursor: pointer;
  text-align: center;
  transition: 0.2s border-color;
}

.EX::after,
.bb::before,
.cW::before,
.yb::after {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  content: "";
}

.cW.TE {
  opacity: 0.5;
}

.kW {
  display: inline-block;
  vertical-align: middle;
}

.cW:not(.Uh) .kW {
  visibility: hidden;
}

.cW .nl,
.hf,
.hf::after {
  position: absolute;
}

.hf {
  bottom: 5px;
  left: 10px;
  max-height: 40px;
  overflow: hidden;
  font-size: 90%;
  line-height: 20px;
  color: #5f6166;
  right: 10px;
}

.hf::after {
  right: 0;
  bottom: 0;
  width: 35px;
  height: 20px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  content: "";
}

.cW .nl {
  top: 12px;
  right: -15px;
  white-space: nowrap;
}

.cW .Ju,
.cW .nv {
  display: inline-block;
  padding: 3px 8px;
  border: 1px solid #edeef2;
  border-radius: 3px;
  background-color: #fff;
  color: #a0a3ad;
  font-size: 75%;
  transition: 0.2s border-color;
}

.cW .nv {
  display: none;
  border: 1px solid #fff;
  background-color: #69c7e2;
  color: #fff;
}

.nv.Tv {
  border-radius: 3px 0 0 3px;
}

.nl:not(.KX) .nv {
  display: inline-block;
}

.nl:not(.KX) .Ju {
  border-radius: 0 3px 3px 0;
  border-left: none;
}

.cW .tC,
.nl.UM {
  display: none;
}

.cW .tC {
  padding-right: 7px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 10px;
}

.uI {
  font-size: 125%;
  line-height: 0;
}

@media (min-width: 769px) {
  .cW:hover {
    border-color: #a3cd73;
  }
  .cW:hover .uI {
    padding-left: 7px;
  }
  .cW:hover .nv,
  .cW:hover .tC {
    display: inline-block;
  }
  .cW:hover .Ju {
    border-color: #d0cfd3;
    border-radius: 0 3px 3px 0;
    border-left: none;
  }
}

@media (max-width: 768px) {
  .cW {
    height: auto;
    padding: 35px 0 40px;
    margin: 0 15px 15px 0;
  }
  .kW {
    width: 80px;
  }
  .hf {
    font-size: 75%;
  }
  .cW .nl {
    right: -10px;
  }
}

// otp

.inputStyle input {
  width: 2.5rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
