
// notification UI


.Toastify__toast {
	background-color: $white !important;
	padding         : 20px  !important;
	border-radius   : 3px !important;
	border-top      : 1px solid $gray-300 !important;
	border-bottom   : 1px solid $gray-300 !important;
	border-right    : 1px solid $gray-300 !important;
  font-family     : inherit;
  z-index         : 99999 !important;
}
.Toastify__toast p {
	color: #23282c !important
}
.Toastify__toast h6 {
	color      : $black !important;
	font-weight: 500 !important;
}

// Toastiy: "SUCCESS" Alert
.Toastify__toast--success .fa-check {
	color: $success !important;
}
.Toastify__toast--success .fa-check-circle {
	color: $success !important;
}
.Toastify__toast--success {
	border-left: 3px solid $success !important;
}
.Toastify__close-button--success {
	color: $gray-500 !important;
}

// Toastiy: "INFO" Alert

.Toastify__toast--info .fa-info {
	color: $primary !important;
}
.Toastify__toast--info {
	background-color: $white !important;
	border-left     : 3px solid $primary !important;
}
.Toastify__close-button--info {
	color: $gray-500 !important;
}

// Toastiy: "WARNING" Alert
.Toastify__toast--warning .fa-exclamation {
	color: $warning !important;
}
.Toastify__toast--warning {
	background-color: $white !important;
	border-left     : 3px solid $warning !important;
}
.Toastify__close-button--warning {
	color: $gray-500 !important;
}

// Toastiy: "ERROR" Alert
.Toastify__toast--error .fa-close {
	color: $danger !important;
}
.Toastify__toast--error {
	background-color: $white !important;
	border-left     : 3px solid $danger !important;
}
.Toastify__close-button--error {
	color: $gray-500 !important;
}

// Toastiy: "DEFAULT" Alert
.Toastify__toast--default .fa-exclamation {
	color: $dark !important;
}
.Toastify__toast--default {
	background-color: $white !important;
	border-left     : 3px solid $secondary !important;
}
.Toastify__close-button--default {
	color: $gray-500 !important;
}